import React from 'react';
import { Th, Thead, Tr } from '@chakra-ui/react';
import { maintenanceTableTitleStrings } from 'src/features/maintenance-data/maintenance-table/const';

export const MaintenanceTableHead = () => {
  return (
    <Thead>
      <Tr sx={thStyles}>
        {maintenanceTableTitleStrings.map((title: string, index: number) => (
          <Th key={index}>{title}</Th>
        ))}
      </Tr>
    </Thead>
  );
};

const thStyles = {
  '& > th': {
    verticalAlign: 'middle',
    fontFamily: `'Montserrat', sans-serif`,
    letterSpacing: 0,
    px: 2,
    py: 3,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#55687D',
    textTransform: 'none',
    whiteSpace: 'normal',
  },
};
