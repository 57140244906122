import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { DMCloseIcon } from 'src/components/Icons';
import { SearchControl } from 'src/components/SearchControl';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'src/app/slices/applicationSlice';

export const SearchInputMobile = ({ onSearchMobileClose }: { onSearchMobileClose: () => void }) => {
  const dispatch = useDispatch();
  const handleSearchClose = () => {
    dispatch(setSearchValue(''));
    onSearchMobileClose();
  };
  return (
    <HStack h={16} bgColor="white" position="fixed" w="full" left={0} borderBottom="2px solid #7071f3" p={4}>
      <SearchControl />
      <Box>
        <DMCloseIcon fontSize="1.5rem" onClick={handleSearchClose} />
      </Box>
    </HStack>
  );
};
