import React, { useEffect, useState } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { useLazyGetBeltMisalignmentQuery, useLazyGetRockSizeQuery } from 'src/app/api/sensorsApi';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BeltMisalignmentPlot } from 'src/features/visual-ai/charts/belt-misalignment-plot/BeltMisalignmentPlot';
import { RockSizePlot } from 'src/features/visual-ai/charts/rock-size-plot/RockSizePlot';
import { AssetSensorsHealthStatus } from 'src/types/assets';
import { useTranslation } from 'react-i18next';
import { EvidenceSidebar } from 'src/features/visual-ai/components/EvidenceSidebar';
import { EvidenceVideo } from 'src/features/visual-ai/components/EvidenceVideo';
import { getCameraSource, getNotVibrationSensors } from 'src/features/visual-ai/utils';
import { LastUpdate } from 'src/pages/machines/components/LastUpdate';
import { diagnosticsDateFormat, FAILURE_NAME, HEALTH_STATUS, SENSOR_TYPE } from 'src/const';
import { add, format, isAfter, sub } from 'date-fns';
import {
  filterMeasurementsByDateRange,
  getLatestMeasurementsForEachDate,
} from 'src/features/visual-ai/charts/rock-size-plot/utils';

import {
  filterDiagnosticsWithMeasurementsForBeltMisalignment,
  filterMeasurementsByDateRangeBeltMisalignment,
  getLatestMeasurementsForEachDateBeltMisalignment,
} from 'src/features/visual-ai/charts/belt-misalignment-plot/utils';
import { Loading } from 'src/components/Loading';
import { ChartDatePicker } from 'src/features/visual-ai/components/ChartDatePicker';
import { SourceInfo } from 'src/types/diagnostics';

interface VisualAIContainerProps {
  asset: any;
  component: any;
  componentLastUptime: any;
  selectedComponent: any;
  revision: number;
  diagnosticsData: any;
}

export const VisualAIContainer = ({
  asset,
  component,
  componentLastUptime,
  selectedComponent,
  revision,
  diagnosticsData,
}: VisualAIContainerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { siteId, machineId, componentId } = useParams<string>();
  const [searchParams] = useSearchParams();
  const fromSearchParam = searchParams.get('from');
  const initialResourceId = searchParams.get('resourceId');
  const selectedFaultType = searchParams.get('faultType');
  const [
    fetchRockSizeData,
    { data: rockSizeData, isLoading: isLoadingRockSize, isFetching: isFetchingRockSize, isError: isErrorRockSize },
  ] = useLazyGetRockSizeQuery({});
  const [
    fetchBeltMisalignmentData,
    {
      data: beltMisalignmentData,
      isLoading: isLoadingBeltMisalignment,
      isFetching: isFetchingBeltMisalignment,
      isError: isErrorBeltMisalignment,
    },
  ] = useLazyGetBeltMisalignmentQuery({});
  const [activeEvidenceResourceId, setActiveEvidenceResourceId] = useState<string | undefined>();
  const [latestRockSizeMeasurements, setLatestRockSizeMeasurements] = useState<any>();
  const [latestBeltMisalignmentMeasurements, setLatestBeltMisalignmentMeasurements] = useState<any>();
  const [isLeftRollerPlotVisible, setIsLeftRollerPlotVisible] = useState<boolean>(false);
  const [isRightRollerPlotVisible, setIsRightRollerPlotVisible] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | undefined>(
    fromSearchParam ? sub(new Date(fromSearchParam), { months: 1 }) : sub(new Date(), { months: 2 })
  );
  const [toDate, setToDate] = useState<Date>(new Date());
  const [minDate, setMinDate] = useState<Date>();
  const [latestDiagnostics, setLatestDiagnostics] = useState<any>([]);
  const [initialDiagnosticsData, setInitialDiagnosticsData] = useState<any>();

  const updateActiveEvidenceResourceId = (id: string | undefined) => setActiveEvidenceResourceId(id);

  const getNoDataDescription = (
    noDataMessage: string,
    noSensorsMessage: string,
    filterFunction: (sensor: AssetSensorsHealthStatus) => any
  ) => {
    const sensors = asset?.sensors_health_status?.filter(filterFunction);
    return sensors?.length ? noDataMessage : noSensorsMessage;
  };

  const fetchData = (queryParams: Record<string, string | undefined>) => {
    fetchRockSizeData({ ...queryParams }, true);
    fetchBeltMisalignmentData({ ...queryParams }, true);
  };

  const getCameraSensors = (sensor: AssetSensorsHealthStatus) =>
    sensor.component_id === selectedComponent.component_id && sensor.sensor_type.toLowerCase() === SENSOR_TYPE.Camera;

  const onEvidenceVideoClose = () => {
    setActiveEvidenceResourceId(undefined);

    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.delete('resourceId');
    currentSearchParams.delete('faultType');
    navigate({
      pathname: location.pathname,
      search: currentSearchParams.toString(),
    });
  };

  const navigateEvidenceResourceId = (direction: number) => {
    const index = latestDiagnostics.findIndex(
      (entry: any) => getCameraSource(entry.sources_info)?.evidence_resource_id === activeEvidenceResourceId
    );

    if (index === -1) return;

    let newIndex = index + direction;
    newIndex = Math.max(0, Math.min(newIndex, latestDiagnostics.length - 1));

    if (latestDiagnostics[newIndex].fault_type.toLowerCase() === FAILURE_NAME.BeltShiftLeft) {
      setIsLeftRollerPlotVisible(true);
      setIsRightRollerPlotVisible(false);
    }

    if (latestDiagnostics[newIndex].fault_type.toLowerCase() === FAILURE_NAME.BeltShiftRight) {
      setIsLeftRollerPlotVisible(false);
      setIsRightRollerPlotVisible(true);
    }

    const newEvidenceId = getCameraSource(latestDiagnostics[newIndex].sources_info)?.evidence_resource_id || undefined;
    setActiveEvidenceResourceId(newEvidenceId);
  };

  const onPreviousVideoClick = () => navigateEvidenceResourceId(1);
  const onNextVideoClick = () => navigateEvidenceResourceId(-1);

  const queryParams: Record<string, string> = {
    site_id: siteId as string,
    asset_id: asset?.asset_id,
    asset_name: asset?.asset_name,
    component_id: component?.component_id || componentId,
    component_name: component?.component_name,
  };

  useEffect(() => {
    if (rockSizeData?.length) {
      const evidenceDates = initialDiagnosticsData.map((entry: any) =>
        new Date(entry.diagnostic_created_at + 'Z').toISOString()
      );
      const xMeasurementsRockSize = rockSizeData[0].measurements.x;
      const filteredResults = filterMeasurementsByDateRange(evidenceDates, xMeasurementsRockSize);
      const measurements = getLatestMeasurementsForEachDate(filteredResults).reverse();

      const dates = [
        ...Object.entries(filteredResults)
          .filter((entry: any) => {
            return entry[1].length;
          })
          .map((entry) => format(new Date(entry[0]), diagnosticsDateFormat)),
      ];

      const filteredDiagnosticsWithMeasurements = [...initialDiagnosticsData].filter((diagnostic: any) => {
        return new Set([...dates]).has(format(new Date(diagnostic.diagnostic_created_at), diagnosticsDateFormat));
      });

      setLatestRockSizeMeasurements(measurements);
      setLatestDiagnostics(filteredDiagnosticsWithMeasurements);
    }
  }, [rockSizeData, initialDiagnosticsData]);

  useEffect(() => {
    if (diagnosticsData.length && fromDate && toDate) {
      setMinDate(new Date('2024-04-08'));

      const filteredDiagnostics: any = [...diagnosticsData]
        .reverse()
        .filter((entry) => new Date(entry[0]) >= fromDate && new Date(entry[0]) <= toDate);

      const diagnosticsResult = filteredDiagnostics
        .map((entry: any) => [entry[0], entry[1]])
        .flatMap((entry: any) =>
          entry[1].assets.flatMap((asset: any) =>
            asset.components.flatMap((component: any) =>
              component.diagnostics.filter((diagnostic: any) => {
                const isDiagnosticHasCameraEvidence = diagnostic.sources_info.some(
                  (source: SourceInfo) =>
                    source.source_type?.toLowerCase() === SENSOR_TYPE.Camera && source.evidence_resource_id !== null
                );
                const isDiagnosticHealthy = diagnostic.health_status === HEALTH_STATUS.HEALTHY;
                const isFaultTypeExists =
                  diagnostic.fault_type?.toLowerCase().includes(FAILURE_NAME.BeltShift) ||
                  diagnostic.fault_type?.toLowerCase() === FAILURE_NAME.GapSize;

                return isDiagnosticHasCameraEvidence && (isDiagnosticHealthy || isFaultTypeExists);
              })
            )
          )
        );

      setLatestDiagnostics(diagnosticsResult);
      setInitialDiagnosticsData(diagnosticsResult);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (fromDate) {
      const todayDate = new Date();
      const newToDate = add(fromDate, { months: 2 });
      if (isAfter(newToDate, todayDate)) {
        setToDate(todayDate);
      } else {
        setToDate(newToDate);
      }
    }

    setActiveEvidenceResourceId(undefined);
  }, [fromDate]);

  useEffect(() => {
    fetchData(queryParams);
  }, [component, machineId]);

  useEffect(() => {
    fetchData({
      ...queryParams,
      to_date: toDate ? format(toDate, diagnosticsDateFormat) : undefined,
      from_date: fromDate ? format(fromDate, diagnosticsDateFormat) : undefined,
    });
  }, []);

  useEffect(() => {
    fetchData({
      ...queryParams,
      to_date: toDate ? format(toDate, diagnosticsDateFormat) : undefined,
      from_date: fromDate ? format(fromDate, diagnosticsDateFormat) : undefined,
    });
  }, [fromDate, toDate]);

  const filterDiagnostics = (diagnostic: any, failure: FAILURE_NAME) => {
    const isDiagnosticHasCameraEvidence = diagnostic.sources_info.some(
      (source: any) => source.source_type.toLowerCase() === SENSOR_TYPE.Camera && source.evidence_resource_id !== null
    );
    const isDiagnosticHealthy = diagnostic.health_status === HEALTH_STATUS.HEALTHY;
    const isFaultTypeExists =
      diagnostic.fault_type.toLowerCase().includes(failure) ||
      diagnostic.fault_type.toLowerCase() === FAILURE_NAME.BeltShift;

    return isDiagnosticHasCameraEvidence && (isDiagnosticHealthy || isFaultTypeExists);
  };

  useEffect(() => {
    if (beltMisalignmentData?.length) {
      const leftEvidenceDates = initialDiagnosticsData
        .filter((diagnostic: any) => filterDiagnostics(diagnostic, FAILURE_NAME.BeltShiftLeft))
        .map((entry: any) => new Date(entry.diagnostic_created_at + 'Z').toISOString());

      const rightEvidenceDates = initialDiagnosticsData
        .filter((diagnostic: any) => filterDiagnostics(diagnostic, FAILURE_NAME.BeltShiftRight))
        .map((entry: any) => new Date(entry.diagnostic_created_at + 'Z').toISOString());

      const xMeasurementsBeltMisalignment = beltMisalignmentData[0].measurements.x;

      const leftFilteredResults = filterMeasurementsByDateRangeBeltMisalignment(
        leftEvidenceDates,
        xMeasurementsBeltMisalignment
      );
      const leftLatestMeasurements = getLatestMeasurementsForEachDateBeltMisalignment(leftFilteredResults);

      const rightFilteredResults = filterMeasurementsByDateRangeBeltMisalignment(
        rightEvidenceDates,
        xMeasurementsBeltMisalignment
      );
      const rightLatestMeasurements = getLatestMeasurementsForEachDateBeltMisalignment(rightFilteredResults);

      const filteredDiagnosticsWithMeasurements = filterDiagnosticsWithMeasurementsForBeltMisalignment(
        leftFilteredResults,
        rightFilteredResults,
        initialDiagnosticsData
      );

      setLatestDiagnostics(filteredDiagnosticsWithMeasurements);

      setLatestBeltMisalignmentMeasurements({
        left: leftLatestMeasurements.reverse(),
        right: rightLatestMeasurements.reverse(),
      });
    }
  }, [beltMisalignmentData, initialDiagnosticsData]);

  useEffect(() => {
    if (!activeEvidenceResourceId && !selectedFaultType) {
      setIsLeftRollerPlotVisible(true);
      setIsRightRollerPlotVisible(true);
    } else if (selectedFaultType) {
      if (selectedFaultType.toLowerCase().includes('left')) {
        setIsLeftRollerPlotVisible(true);
        setIsRightRollerPlotVisible(false);
      } else if (selectedFaultType.toLowerCase().includes('right')) {
        setIsLeftRollerPlotVisible(false);
        setIsRightRollerPlotVisible(true);
      } else {
        setIsLeftRollerPlotVisible(true);
        setIsRightRollerPlotVisible(false);
      }
    }
  }, [activeEvidenceResourceId, selectedFaultType]);

  useEffect(() => {
    if (initialResourceId) {
      setActiveEvidenceResourceId(initialResourceId);
    }
  }, [initialResourceId]);

  const updateFromDate = (date: Date | undefined) => {
    setFromDate(date);
  };

  const isRockOrBeltTitle = rockSizeData?.length
    ? t('plots.camera.title.rockSize')
    : beltMisalignmentData?.length
    ? t('plots.camera.title.beltMisalignment')
    : null;

  return (
    <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing={4} position="relative">
      {!isLoadingRockSize || !isLoadingBeltMisalignment ? (
        <ChartDatePicker
          fromDate={fromDate}
          toDate={toDate}
          minDate={minDate}
          updateFromDate={updateFromDate}
          isFetching={isFetchingRockSize || isFetchingBeltMisalignment}
        />
      ) : null}

      {isLoadingRockSize || isLoadingBeltMisalignment ? (
        <Loading w="full" />
      ) : (
        <>
          <VStack w="full" spacing={4} opacity={isFetchingRockSize || isFetchingBeltMisalignment ? 0.5 : 1}>
            {activeEvidenceResourceId ? (
              <EvidenceVideo
                resourceId={activeEvidenceResourceId}
                onClose={onEvidenceVideoClose}
                onPrev={onPreviousVideoClick}
                onNext={onNextVideoClick}
                isRockOrBeltTitle={isRockOrBeltTitle}
              />
            ) : null}

            {beltMisalignmentData?.length &&
            latestBeltMisalignmentMeasurements &&
            Array.from(
              new Set([...latestBeltMisalignmentMeasurements.left, ...latestBeltMisalignmentMeasurements.right])
            )?.length === latestDiagnostics?.length ? (
              <BeltMisalignmentPlot
                title={t('plots.camera.title.beltMisalignment')}
                noDataDescription={getNoDataDescription(
                  t('plots.camera.noData'),
                  t('plots.camera.noSensors'),
                  getNotVibrationSensors
                )}
                lastUpdateComponent={
                  <LastUpdate
                    siteId={siteId}
                    asset={asset}
                    componentLastUptime={componentLastUptime}
                    filterFunction={getCameraSensors}
                  />
                }
                errorMessage={t('plots.camera.error.beltMisalignment')}
                beltMisalignmentData={beltMisalignmentData}
                isFetchingBeltMisalignment={isFetchingBeltMisalignment}
                isLoadingBeltMisalignment={isLoadingBeltMisalignment}
                isErrorBeltMisalignment={isErrorBeltMisalignment}
                revision={revision}
                latest20Diagnostics={latestDiagnostics}
                latestBeltMisalignmentMeasurements={latestBeltMisalignmentMeasurements}
                activeEvidenceResourceId={activeEvidenceResourceId}
                updateActiveEvidenceResourceId={updateActiveEvidenceResourceId}
                isLeftRollerPlotVisible={isLeftRollerPlotVisible}
                isRightRollerPlotVisible={isRightRollerPlotVisible}
                setIsLeftRollerPlotVisible={setIsLeftRollerPlotVisible}
                setIsRightRollerPlotVisible={setIsRightRollerPlotVisible}
              />
            ) : null}

            {rockSizeData?.length && latestRockSizeMeasurements?.length === latestDiagnostics?.length ? (
              <RockSizePlot
                title={t('plots.camera.title.rockSize')}
                noDataDescription={getNoDataDescription(
                  t('plots.camera.noData'),
                  t('plots.camera.noSensors'),
                  getNotVibrationSensors
                )}
                lastUpdateComponent={
                  <LastUpdate
                    siteId={siteId}
                    asset={asset}
                    componentLastUptime={componentLastUptime}
                    filterFunction={getCameraSensors}
                  />
                }
                errorMessage={t('plots.camera.error.rockSize')}
                rockSizeData={rockSizeData}
                isFetchingRockSize={isFetchingRockSize}
                isLoadingRockSize={isLoadingRockSize}
                isErrorRockSize={isErrorRockSize}
                revision={revision}
                latest20Diagnostics={latestDiagnostics}
                latestRockSizeMeasurements={latestRockSizeMeasurements}
                activeEvidenceResourceId={activeEvidenceResourceId}
                updateActiveEvidenceResourceId={updateActiveEvidenceResourceId}
              />
            ) : null}
          </VStack>

          <Box
            opacity={
              isFetchingRockSize || isFetchingBeltMisalignment || isLoadingBeltMisalignment || isLoadingRockSize
                ? 0.5
                : 1
            }
          >
            <EvidenceSidebar
              latestDiagnostics={(() => {
                if (rockSizeData?.length && latestRockSizeMeasurements?.length === latestDiagnostics?.length) {
                  return latestDiagnostics.map((item: any, index: number) => ({
                    ...item,
                    original_diagnostic_created_at: item.diagnostic_created_at,
                    diagnostic_created_at: format(new Date(latestRockSizeMeasurements[index]), diagnosticsDateFormat),
                  }));
                }

                if (
                  beltMisalignmentData?.length &&
                  latestBeltMisalignmentMeasurements &&
                  latestDiagnostics?.length ===
                    Array.from(
                      new Set([...latestBeltMisalignmentMeasurements.left, ...latestBeltMisalignmentMeasurements.right])
                    ).length
                ) {
                  const mergedLatestBeltMisalignmentMeasurements = Array.from(
                    new Set([...latestBeltMisalignmentMeasurements.left, ...latestBeltMisalignmentMeasurements.right])
                  );

                  return latestDiagnostics.map((item: any, index: number) => ({
                    ...item,
                    original_diagnostic_created_at: item.diagnostic_created_at,
                    diagnostic_created_at: format(
                      new Date(mergedLatestBeltMisalignmentMeasurements[index]),
                      diagnosticsDateFormat
                    ),
                  }));
                }

                return [];
              })()}
              activeEvidenceResourceId={activeEvidenceResourceId}
              updateActiveEvidenceResourceId={updateActiveEvidenceResourceId}
              setIsLeftRollerPlotVisible={setIsLeftRollerPlotVisible}
              setIsRightRollerPlotVisible={setIsRightRollerPlotVisible}
            />
          </Box>
        </>
      )}
    </HStack>
  );
};
