import React from 'react';
import { Asset, AssetsView } from 'src/types/assets';
import { SENSORS_DATA_TYPE, NOT_MONITORED_DAYS } from 'src/const';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { MachineGridItem } from 'src/pages/machines/components/MachineGridItem';
import { MachineListItem } from 'src/pages/machines/components/MachineListItem';

type MachineItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  siteLastVerified?: string;
  selectedAssetsView: AssetsView;
};

export const MachineItem = ({ item, isRequireAttention, siteLastVerified, selectedAssetsView }: MachineItemProps) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const onMachineItemClick = () => {
    navigate(generatePath(locations.protected.sites.components, { siteId, machineId: item.asset_id }));
  };

  const hasCamera: boolean = item.sensors_health_status.some(
    (sensor: any) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
  );

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored = siteLastVerified
    ? new Date(item.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate
    : false;

  return (
    <>
      {selectedAssetsView === 'grid' ? (
        <MachineGridItem
          item={item}
          isRequireAttention={isRequireAttention}
          siteId={siteId}
          onMachineItemClick={onMachineItemClick}
          hasCamera={hasCamera}
          isNotMonitored={isNotMonitored}
        />
      ) : (
        <MachineListItem
          item={item}
          isRequireAttention={isRequireAttention}
          siteId={siteId}
          onMachineItemClick={onMachineItemClick}
          hasCamera={hasCamera}
          isNotMonitored={isNotMonitored}
        />
      )}
    </>
  );
};
