import React from 'react';
import { Box, HStack, VStack, Tooltip, Text, Image } from '@chakra-ui/react';
import { format } from 'date-fns';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { Asset } from 'src/types/assets';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { AssetFaultType } from 'src/pages/machines/components/AssetFaultType';

type MachineGridItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  siteId: string | undefined;
  onMachineItemClick: () => void;
  hasCamera: boolean;
  isNotMonitored: boolean;
};

export const MachineGridItem = ({
  item,
  isRequireAttention,
  siteId,
  onMachineItemClick,
  hasCamera,
  isNotMonitored,
}: MachineGridItemProps) => {
  return (
    <VStack
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      height="100%"
      p="0.75rem"
      spacing={2.5}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={onMachineItemClick}
    >
      <HStack w="full" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="3px" w="250px">
          <Text fontSize="14px" fontWeight={600} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {item.asset_ui_name}
          </Text>
          <Text fontSize="12px" color="#55687D" fontWeight="500">
            Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
          </Text>
        </Box>
        <VStack spacing={1} alignItems="flex-end">
          {isNotMonitored ? (
            <Box
              color="white"
              fontWeight={600}
              borderRadius="8px"
              background="#01152B"
              textAlign="center"
              pl={2}
              pr={2}
              fontSize="10px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              Not Monitored
            </Box>
          ) : null}
        </VStack>
      </HStack>

      <HStack w="full" justifyContent="space-between" alignItems="center">
        <HStack spacing={2} alignItems="center">
          <HealthStatusIndicator type={item.health_status} />
          {item.health_status_changed && (
            <Tooltip label="Health status changed" borderRadius="md">
              <Text fontSize="10px" color="#01152B" fontWeight={600} whiteSpace="nowrap" pt={0.5}>
                Status Change
              </Text>
            </Tooltip>
          )}
        </HStack>
        <HStack alignItems="center" gap="15px">
          {hasCamera && (
            <Tooltip label="Camera" borderRadius="md">
              <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
            </Tooltip>
          )}

          <MachineSensorsModal
            sensors={item.sensors_health_status}
            title={item.asset_ui_name}
            siteId={siteId!}
            assetId={item.asset_id}
          />
        </HStack>
      </HStack>

      {isRequireAttention &&
        !!item.most_severe_diagnostics[0].fault_type &&
        (item.health_status === HEALTH_STATUS.CRITICAL || item.health_status === HEALTH_STATUS.ALARM) && (
          <Box w="full">
            <AssetFaultType
              faultType={item.most_severe_diagnostics[0].fault_type}
              variant={item.health_status === HEALTH_STATUS.CRITICAL ? 'critical' : 'alarm'}
            />
          </Box>
        )}
    </VStack>
  );
};
