import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@chakra-ui/react';
import { summary, SummaryDataType } from 'src/data/summary';
import { SummaryChart } from 'src/pages/sites/components/summary/components/SummaryChart';
import { SummaryHeader } from 'src/pages/sites/components/summary/components/SummaryHeader';
import { SummaryLegend } from 'src/pages/sites/components/summary/components/SummaryLegend';
import { Site } from 'src/types/sites';
import { SummaryTotalAssets } from './SummaryTotalAssets';

interface SummaryProps {
  sites: Site[];
  activeTabIndex?: number;
}

export const Summary = ({ sites, activeTabIndex }: SummaryProps) => {
  const isAllAssetsTab = activeTabIndex === 0;
  const initialAssetsHealthState: Record<string, number> = {
    critical: 0,
    alarm: 0,
    monitor: 0,
    healthy: 0,
    na: 0,
  };

  sites.forEach((item) => {
    const assetsHealthStatus: Record<string, number> = item.assets_health_state;
    for (const key in initialAssetsHealthState) {
      initialAssetsHealthState[key] = initialAssetsHealthState[key] + assetsHealthStatus[key];
    }
  });

  const assetsHealthStatusSummary = summary.map((item) => {
    if (initialAssetsHealthState[item.id]) {
      return {
        ...item,
        value: initialAssetsHealthState[item.id],
      };
    } else {
      return item;
    }
  });

  const total = assetsHealthStatusSummary.reduce((total: number, item: SummaryDataType) => total + item.value, 0);

  return (
    <Box
      w={{ base: '280px' }}
      px={{ base: 4, lg: '1rem' }}
      pl={{ base: 4, lg: '0' }}
      pt={{ base: 0, lg: '9.9rem' }}
      pb={{ base: 4, lg: 6 }}
      top={{ base: 0, lg: 0 }}
      css={style}
    >
      <Box bgColor="#E6E8EC" p={2} borderRadius="lg" h="100%">
        <Box bgColor="#ffffff" borderRadius="lg" h="100%" p={4} overflowY="auto" textTransform="capitalize">
          <SummaryHeader />
          <SummaryChart data={assetsHealthStatusSummary} total={total} />
          {isAllAssetsTab ? <SummaryTotalAssets totalFixedAssets={total} /> : null}
          <SummaryLegend data={assetsHealthStatusSummary} />
        </Box>
      </Box>
    </Box>
  );
};

const style = css`
  background-color: white;
  position: fixed;
  right: 0;
  height: 100vh;
  z-index: 3;
`;
