import React, { Suspense, useEffect, useState } from 'react';
import { Box, Hide, useToast, Tab, TabList, TabPanel, TabPanels, Tabs, HStack, Tooltip } from '@chakra-ui/react';
import { useLazyGetAssetsQuery, useLazyGetSitesQuery } from 'src/app/api/diagnosticApi';
import { Toolbar } from 'src/components/Toolbar';
import { Summary } from 'src/pages/sites/components/summary/Summary';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { START_PAGE_TOAST_ID, TOAST_STATUS } from 'src/const';
import { useDispatch, useSelector } from 'react-redux';
import { searchValueSelector } from 'src/app/store';
import { analytics } from 'src/analytics';
import { setSearchValue } from 'src/app/slices/applicationSlice';
import { Header } from 'src/components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from 'src/components/Fallback';
import { css } from '@emotion/react';
import { SiteTabContent } from 'src/pages/sites/SiteTabContent';

export const SitesPages = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);
  const [fetchSites, { isLoading, data: sites, isError }] = useLazyGetSitesQuery();
  const [fetchAssets] = useLazyGetAssetsQuery();
  const [searchResults, setSearchResults] = useState<any>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    setActiveTabIndex(index);
  };
  const handleSearch = (value: string) => {
    const filteredResults = sites && sites.filter((item) => item.site_name.toLowerCase().includes(value.toLowerCase()));
    setSearchResults(filteredResults);
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, sites]);

  useEffect(() => {
    dispatch(setSearchValue(''));

    fetchSites({}, true)
      .unwrap()
      .catch((error) => {
        if (!toast.isActive(START_PAGE_TOAST_ID)) {
          toast({
            id: START_PAGE_TOAST_ID,
            status: TOAST_STATUS.Error,
            title: t('general.errors.title'),
            description: t('general.errors.communication'),
          });
        }
        Sentry.captureException(
          error?.data?.message || error?.error || error?.message || error?.originalError || error
        );
      });
  }, []);

  useEffect(() => {
    if (sites) {
      for (const site of sites) {
        fetchAssets(
          {
            site_id: site.site_id,
          },
          true
        );
      }
    }
  }, [sites]);

  useEffect(() => {
    analytics.page({
      name: 'Sites',
      title: 'DataMind Dashboard 2.0 - Sites',
    });
  }, []);

  return (
    <>
      <Suspense>
        <Header>
          <Toolbar sites={sites} />
        </Header>

        <Box {...containerProps}>
          <Tabs index={activeTabIndex} onChange={onTabChange} variant="unstyled" isLazy>
            <HStack justifyContent="space-between">
              <TabList bgColor="#ffffff" borderRadius="8px" w="auto" border="1px solid #e2e8f0" boxShadow="sm">
                <Tab
                  bg="#ffffff"
                  borderTopLeftRadius="8px"
                  borderBottomLeftRadius="8px"
                  borderTopRightRadius="0"
                  borderBottomRightRadius="0"
                  fontWeight={500}
                  _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                  {...tabProps}
                >
                  All Assets
                </Tab>
                <Tab
                  bg="#ffffff"
                  fontWeight={500}
                  _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                  {...tabProps}
                >
                  Fixed Assets
                </Tab>
                <Tab
                  bg="#ffffff"
                  borderTopRightRadius="8px"
                  borderBottomRightRadius="8px"
                  borderTopLeftRadius="0"
                  borderBottomLeftRadius="0"
                  fontWeight={500}
                  isDisabled
                  _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                  {...tabProps}
                >
                  <Tooltip label="No Assets Available">Mobile Assets</Tooltip>
                </Tab>
              </TabList>
            </HStack>
            <TabPanels h="full" w="full" bgColor="transparent">
              <TabPanels h="full" w="full" bgColor="transparent">
                <SiteTabContent
                  isLoading={isLoading}
                  isError={isError}
                  searchResults={searchResults}
                  activeTabIndex={activeTabIndex}
                  tabPanelStyle={tabPanelStyle}
                />
                <SiteTabContent
                  isLoading={isLoading}
                  isError={isError}
                  searchResults={searchResults}
                  activeTabIndex={activeTabIndex}
                  tabPanelStyle={tabPanelStyle}
                />
              </TabPanels>
            </TabPanels>
          </Tabs>
        </Box>

        <Hide below="xl">
          {!isLoading && sites ? (
            <ErrorBoundary FallbackComponent={Fallback}>
              <Summary sites={sites} activeTabIndex={activeTabIndex} />
            </ErrorBoundary>
          ) : null}
        </Hide>
      </Suspense>
    </>
  );
};

const containerProps = {
  w: 'full',
  px: { base: 0, xl: 8 },
  pr: { base: 4, xl: '300px' },
  pt: '5.5rem',
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  background-color: transparent;
`;
