import React from 'react';
import { MaintenanceTaskRaw } from 'src/features/maintenance-data/maintenance-chart/types';
import { Tbody, Td, Tooltip, Tr } from '@chakra-ui/react';
import { truncateString } from 'src/utils';
import { format } from 'date-fns';
import { SHORT_YEAR_DATE_FORMAT } from 'src/const';

type MaintenanceTableBodyProps = {
  data: MaintenanceTaskRaw[];
};

export const MaintenanceTableBody = ({ data }: MaintenanceTableBodyProps) => (
  <Tbody>
    {data.map((item: MaintenanceTaskRaw, index: number) => (
      <Tr key={index} sx={tdStyles}>
        <Td
          whiteSpace="normal"
          borderTopLeftRadius={index === 0 ? 'lg' : undefined}
          borderBottomLeftRadius={index === data.length - 1 ? 'lg' : undefined}
        >
          {item.workOrderNumber}
        </Td>
        <Td>{item.orderType}</Td>
        <Td sx={tdHoverStyles}>
          <Tooltip label={item.actualWorkDescription} borderRadius="lg">
            {truncateString(item.actualWorkDescription, 25)}
          </Tooltip>
        </Td>
        <Td>{item.equipmentNumber}</Td>
        <Td>{item.componentName}</Td>
        <Td>{item.maintenanceActivityType}</Td>
        <Td>{format(new Date(item.actualStartDate), SHORT_YEAR_DATE_FORMAT)}</Td>
        <Td>{format(new Date(item.actualEndDate), SHORT_YEAR_DATE_FORMAT)}</Td>
        <Td>{item.workOrderStatus}</Td>
        <Td>{item.laborHours}</Td>
        <Td sx={tdHoverStyles}>
          <Tooltip label={item.personResponsible} borderRadius="lg">
            {truncateString(item.personResponsible, 12)}
          </Tooltip>
        </Td>
        <Td>{item.priority}</Td>
        <Td
          borderTopRightRadius={index === 0 ? 'lg' : undefined}
          borderBottomRightRadius={index === data.length - 1 ? 'lg' : undefined}
        >
          {item.plantNumber}
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const tdHoverStyles = {
  '&:hover': {
    bgColor: '#F0F2F7',
    cursor: 'default',
  },
};

const tdStyles = {
  '& > td': {
    fontSize: '0.875rem',
    fontWeight: 500,
    px: 2,
    py: 2.5,
    bgColor: 'white',
    whiteSpace: 'normal',
  },
};
