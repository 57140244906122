import React from 'react';
import { Tab, TabList, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ComponentTabListProps {
  selectedComponent?: any;
  isComponentHasCameraSensors: boolean;
  hasCamera?: boolean;
}
export const ComponentTabList = ({
  selectedComponent,
  isComponentHasCameraSensors,
  hasCamera,
}: ComponentTabListProps) => {
  const { t } = useTranslation();
  const isSensorDataTabDisabled = selectedComponent === null;

  return (
    <TabList bgColor="#ffffff" borderRadius="8px" w="auto" border="1px solid #B1B9C3">
      <Tab
        bg="#ffffff"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        fontWeight={500}
        _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
        {...tabProps}
      >
        {t('page.studio.tabs.diagnostics.title')}
      </Tab>

      <Tab
        bg="#ffffff"
        borderRadius="0"
        fontWeight={500}
        _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
        isDisabled={hasCamera || isSensorDataTabDisabled}
        {...tabProps}
      >
        {isSensorDataTabDisabled ? (
          <Tooltip label={t('page.studio.tabs.sensorData.tooltip')} borderRadius="md">
            {t('page.studio.tabs.sensorData.title')}
          </Tooltip>
        ) : (
          t('page.studio.tabs.sensorData.title')
        )}
      </Tab>

      <Tab
        bg="#ffffff"
        borderRadius="0"
        fontWeight={500}
        _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
        isDisabled={!isComponentHasCameraSensors || isSensorDataTabDisabled}
        {...tabProps}
      >
        {t('page.studio.tabs.visualAI.title')}
      </Tab>

      <Tab
        bg="#ffffff"
        borderTopRightRadius="8px"
        borderBottomRightRadius="8px"
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        fontWeight={500}
        _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
        {...tabProps}
      >
        {t('page.studio.tabs.maintenance.title')}
      </Tab>
    </TabList>
  );
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};
