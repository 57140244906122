import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  isFirebaseRequesting: boolean;
  searchValue: string;
  notificationsFeatureFlag: boolean;
  isMfaExpired: boolean;
  isMaintenanceChartVisible: Record<string, boolean>;
} = {
  isFirebaseRequesting: true,
  searchValue: '',
  notificationsFeatureFlag: false,
  isMfaExpired: false,
  isMaintenanceChartVisible: {
    onDiagnostics: true,
    onSensorFusion: true,
  },
};

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setIsFirebaseRequesting: (state, action) => {
      state.isFirebaseRequesting = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setNotificationFeatureFlag: (state, action) => {
      state.notificationsFeatureFlag = action.payload;
    },
    setMfaExpired: (state, action) => {
      state.isMfaExpired = action.payload;
    },
    setIsMaintenanceChartVisible: (state, action) => {
      state.isMaintenanceChartVisible = {
        ...state.isMaintenanceChartVisible,
        ...action.payload,
      };
    },
  },
});

export const {
  setIsFirebaseRequesting,
  setSearchValue,
  setNotificationFeatureFlag,
  setMfaExpired,
  setIsMaintenanceChartVisible,
} = appSlice.actions;
