import { statusColors } from 'src/const';
import { format, subMonths } from 'date-fns';
import { option } from 'src/pages/machines/statistics/machine-status-chart/config';
import { BarSeriesOption } from 'echarts/charts';
import { LATEST_YEAR_OPTION } from 'src/pages/machines/statistics/machine-status-chart/MachineStatusChart';

const transpose2DArray = (matrix: Array<any>) => {
  return matrix[0].map((_: any, columnIndex: number) => matrix.map((row) => row[columnIndex]));
};

export const calculatePercentage = (data: Array<any>) => {
  const resultArray: any[] = [];
  const lengthsArray: number[] = [];

  data.forEach((subArray: any) => {
    const length = Array.from(new Set(subArray[1].map(JSON.stringify))).map((item: any) => JSON.parse(item)).length;
    lengthsArray.push(length);
  });

  const maxLength = Math.max(...lengthsArray);

  data.forEach((subArray: any) => {
    const statusArray = Array.from(new Set(subArray[1].map(JSON.stringify))).map((item: any) => JSON.parse(item));

    let naCount = 0;
    let healthyCount = 0;
    let monitorCount = 0;
    let alarmCount = 0;
    let criticalCount = 0;

    statusArray.forEach((item: any) => {
      const value = Object.values(item)[0];

      switch (value) {
        case 'na':
          naCount++;
          break;
        case 'healthy':
          healthyCount++;
          break;
        case 'monitor':
          monitorCount++;
          break;
        case 'alarm':
          alarmCount++;
          break;
        case 'critical':
          criticalCount++;
          break;
        default:
          break;
      }
    });

    const naPercentage: number = (naCount / maxLength) * 100;
    const healthyPercentage: number = (healthyCount / maxLength) * 100;
    const monitorPercentage: number = (monitorCount / maxLength) * 100;
    const alarmPercentage: number = (alarmCount / maxLength) * 100;
    const criticalPercentage: number = (criticalCount / maxLength) * 100;

    resultArray.push([
      Number(naPercentage.toFixed(2)),
      Number(healthyPercentage.toFixed(2)),
      Number(monitorPercentage.toFixed(2)),
      Number(alarmPercentage.toFixed(2)),
      Number(criticalPercentage.toFixed(2)),
    ]);
  });

  return transpose2DArray(resultArray);
};

export const legendFormatter = (value: string) => {
  const name = value === 'na' ? 'Not Monitored' : value;

  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const tooltipFormatter = (data: any) => {
  return (params: any) => {
    let machinesList = '';
    const machines = getMachinesByStatus(data, params.name, params.seriesName);

    machines.forEach((item: Array<string>) => (machinesList += `${item[0]}<br>`));

    return `<div style="color: #021d3d; font-size: 12px; padding-left: 4px; padding-right: 4px">
              <div style="display: flex; align-items: center; ">
                <div style="width: 8px; height: 8px; margin-right: 8px; border-radius: 100%; background-color: ${
                  params.color
                }"></div>
                <div style="text-transform: capitalize; font-weight: 600">${
                  params.seriesName === 'na' ? 'Not Monitored' : params.seriesName
                }</div>
              </div>
              <div style="padding-top: 12px">${machinesList}</div>
            </div>`;
  };
};

export const filterDataBySpecificMonth = (activeMonth: string, data: any) => {
  const date = new Date(activeMonth);
  const year = format(date, 'yyyy');
  const month = format(date, 'MM');

  return data.filter((entry: any) => {
    const [entryDate] = entry;
    const [entryYear, entryMonth] = entryDate.split('-').map(String);

    return entryYear === year && entryMonth === month;
  });
};

export const getMachinesByStatus = (machineStatusData: any, date: string, status: string) => {
  const entry = machineStatusData.filter((item: any) => item[0] === date);
  const machines: any[] = entry[0][1].map((item: any) => Object.entries(item)[0]);
  const uniqueMachines = machines.reduce((accumulator, currentArray) => {
    const exists = accumulator.some((arr: any) => arr[0] === currentArray[0] && arr[1] === currentArray[1]);

    if (!exists) {
      accumulator.push(currentArray);
    }

    return accumulator;
  }, []);

  return uniqueMachines.filter((item: any) => item[1] === status);
};

const filterByMostSevereStatus = (data: any) => {
  const getStatusPriority = (status: string) => {
    switch (status) {
      case 'critical':
        return 1;
      case 'alarm':
        return 2;
      case 'monitor':
        return 3;
      case 'healthy':
        return 4;
      case 'na':
        return 5;
      default:
        return Infinity;
    }
  };

  const reducedData = data.reduce((acc: any, current: any) => {
    const machineName = Object.keys(current)[0];
    const status = current[machineName];

    if (acc[machineName]) {
      const currentPriority = getStatusPriority(acc[machineName]);
      const newPriority = getStatusPriority(status);

      if (newPriority < currentPriority) {
        acc[machineName] = status;
      }
    } else {
      acc[machineName] = status;
    }

    return acc;
  }, {});

  return data.filter((item: any) => {
    const machineName = Object.keys(item)[0];
    const status = item[machineName];

    return reducedData[machineName] === status;
  });
};

export const extractYears = (data: any) => {
  const yearsObj: any = {};

  Object.keys(data).forEach((key) => {
    const year = parseInt(data[key][0].substring(0, 4));
    yearsObj[year] = true;
  });

  return Object.keys(yearsObj).map(Number);
};

const fillMissingMonthsForYears = (array: any, years: any) => {
  years.forEach((year: any) => {
    const months = [];

    for (let month = 0; month < 12; month++) {
      const monthDate = new Date(Date.UTC(year, month, 1));
      months.push(monthDate.toISOString());
    }

    months.forEach((month) => {
      const found = array.some(([date]: any) => date === month);
      if (!found) {
        array.push([month, []]);
      }
    });
  });

  array.sort((a: any, b: any) => {
    return new Date(a[0]).valueOf() - new Date(b[0]).valueOf();
  });
};

export const mergeEntitiesByMonth = (entities: any, years: any, selectedYear?: string) => {
  const result: any = [];
  const groupedByMonth: any = {};
  const daysInMonth: any = {};

  entities.forEach(([date, items]: any) => {
    const month = date.substring(0, 7);

    if (!groupedByMonth[month]) {
      groupedByMonth[month] = [];
      daysInMonth[month] = 0;
    }
    daysInMonth[month] = daysInMonth[month] + 1;
    groupedByMonth[month].push(...items);
  });

  for (const [month, items] of Object.entries(groupedByMonth)) {
    const isoDate = new Date(month + '-01').toISOString();

    if (daysInMonth[month] >= 28) {
      result.push([isoDate, filterByMostSevereStatus(items)]);
    }
  }

  if (selectedYear !== LATEST_YEAR_OPTION) {
    fillMissingMonthsForYears(result, years);
  }

  const transformLatestResult = (result: any) => {
    const length = result.length;

    if (length === 0) {
      return result;
    }

    if (length === 12) {
      return result;
    }

    if (length > 12) {
      return result.splice(0, length - 12);
    }

    if (length < 12) {
      const diff = 12 - length;
      const firstElement = result[0];
      const filledMonths = [];

      for (let i = diff; i > 0; i--) {
        const dateString = subMonths(new Date(firstElement[0]), i).toISOString();
        filledMonths.push([dateString, []]);
      }

      return [...filledMonths, ...result];
    }

    return result;
  };

  const selectedYearData =
    selectedYear !== LATEST_YEAR_OPTION
      ? result.filter(([date, _]: any) => {
          const year = new Date(date).getFullYear().toString();
          return year === selectedYear;
        })
      : transformLatestResult(result);

  return selectedYearData;
};

export const groupDaysIntoColumns = (entities: any) => {
  const elementsPerSubarray = Math.floor(entities.length / 12);
  const remainingElements = entities.length % 12;
  const groupedArrays = [];
  const mergedArrays = [];

  let startIndex = 0;

  for (let i = 0; i < 12; i++) {
    const endIndex = startIndex + elementsPerSubarray + (i < remainingElements ? 1 : 0);
    const group = entities.slice(startIndex, endIndex);
    groupedArrays.push(group);
    startIndex = endIndex;
  }

  for (const group of groupedArrays) {
    const startDate = group[0][0];
    const endDate = group[group.length - 1][0];

    const mergedData = group.reduce((acc: any, [, data]: any) => {
      return acc.concat(data);
    }, []);

    mergedArrays.push([`${startDate} + ${endDate}`, filterByMostSevereStatus(mergedData)]);
  }

  return mergedArrays;
};

export const xAxisLabelFormatter = (activeMonth: string | null) => {
  return (value: string) => {
    return activeMonth
      ? (() => {
          const dates = value.split('+');

          return dates.length > 1
            ? `${format(new Date(dates[0].trim()), 'd.MM')} - ${format(new Date(dates[1].trim()), 'd.MM')}`
            : value;
        })()
      : format(new Date(value), 'LLL yyyy');
  };
};

export const getSeries = (data: any, machineStatusData: any): Array<BarSeriesOption> => {
  return ['na', 'healthy', 'monitor', 'alarm', 'critical'].map((name, sid) => {
    return {
      name,
      type: 'bar',
      stack: 'total',
      stackStrategy: 'all',
      barWidth: '85%',
      itemStyle: { color: statusColors[name.toLowerCase()] },
      label: {
        show: true,
        color: '#fff',
        fontSize: 11,
        fontWeight: 600,
        formatter: (params: any) => {
          const isValue = params.value > 0;
          const machines = isValue ? getMachinesByStatus(machineStatusData, params.name, params.seriesName) : [];

          return isValue ? `${machines.length}` : '';
        },
      },
      emphasis: {
        focus: 'none',
      },
      data: data[sid].map((d: any) => d),
    };
  });
};

export const getOption = (dataset: any, series: any, activeMonth: string | null, gridHeight: number) => {
  return {
    ...option,
    xAxis: {
      ...option.xAxis,
      data: dataset.map((item: any) => item[0]),
      axisLabel: {
        fontSize: 10,
        color: '#021d3d',
        fontWeight: 400,
        formatter: xAxisLabelFormatter(activeMonth),
      },
    },
    legend: {
      ...option.legend,
      formatter: legendFormatter,
      bottom: 12,
    },
    tooltip: {
      formatter: tooltipFormatter(dataset),
    },
    series: series.slice().reverse(),
    grid: {
      height: gridHeight,
      top: 0,
      left: 20,
      right: 20,
    },
  };
};
