import React from 'react';
import { Box, Button, HStack, VStack, ComponentWithAs, IconProps, Icon } from '@chakra-ui/react';
import { format } from 'date-fns';
import { SENSOR_TYPE, SENSORS_FUSION_SELECTION_FORMAT } from 'src/const';
import { SensorRow } from 'src/features/sensor-fusion/SensorRow';
import { findDifferences } from 'src/features/sensor-fusion/utils';
import { DMComputerMouseLeftClickIcon, DMComputerMouseRightClickIcon } from 'src/components/Icons';

interface SensorFusionCardPlaceholderProps {
  message: string;
  icon: ComponentWithAs<'svg', IconProps>;
}

const SensorFusionCardPlaceholder = ({ message, icon }: SensorFusionCardPlaceholderProps) => {
  return (
    <Box
      fontSize="0.75rem"
      fontWeight={500}
      color="#55687D"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="0px"
      paddingX="3.5rem"
      textAlign="center"
      lineHeight="15px"
    >
      <Icon as={icon} w="3.5rem" h="3.5rem" />
      {message}
    </Box>
  );
};

interface SensorFusionCardProps {
  selectedData: any;
  pinnedData?: any;
  isPinned?: boolean;
  onUnpinSelection?: () => void;
}

export const SensorFusionCard = ({ selectedData, pinnedData, isPinned, onUnpinSelection }: SensorFusionCardProps) => {
  const selectedValues = selectedData?.map((item: any) => ({
    id: item.data.id,
    value: item.y.toFixed(2),
  }));
  const pinnedValues = pinnedData?.map((item: any) => ({
    id: item.data.id,
    value: item.y.toFixed(2),
  }));

  const diffValues = selectedValues && pinnedValues ? findDifferences(selectedValues, pinnedValues) : undefined;

  const topSensors = selectedData?.filter((item: any) =>
    [SENSOR_TYPE.Vibration, SENSOR_TYPE.Vibrations].includes(item.data.sensorType.toLowerCase())
  );

  const middleSensors = selectedData?.filter((item: any) =>
    ['oil-temperature', 'oil temperature', 'pressure', 'temperature', 'temprature'].includes(
      item.data.sensorType.toLowerCase()
    )
  );

  const bottomSensors = selectedData?.filter((item: any) =>
    ['oil-condition', 'oil condition', 'oil-condition', 'oil-quality', 'speed', 'current'].includes(
      item.data.sensorType.toLowerCase()
    )
  );

  return (
    <VStack
      bgColor="white"
      borderRadius="lg"
      w="full"
      alignItems="flex-start"
      border={isPinned ? '2px dashed black' : '2px solid black'}
      flex="1"
      minHeight="0px"
      pb="0.5rem"
      maxW="full"
    >
      {selectedData && (
        <>
          <HStack w="full" justifyContent="space-between" p={4} pb={0} alignItems="center">
            <Box fontSize="1rem" fontWeight={600}>
              {format(new Date(selectedData[0].x), SENSORS_FUSION_SELECTION_FORMAT)}
            </Box>

            {isPinned && (
              <Button
                variant="link"
                colorScheme="DMRadioColorScheme"
                fontSize="0.875rem"
                fontWeight={500}
                onClick={onUnpinSelection}
              >
                Unpin
              </Button>
            )}
          </HStack>

          <Box fontSize="0.875rem" fontWeight={500} color="rgba(2, 29, 61, 0.6)" px={4}>
            {isPinned ? 'Right-click the graph on a compared date' : 'Left-click the graph on a selected date'}
          </Box>

          <VStack w="full" alignItems="flex-start" spacing={0} flex="1" minHeight="0px" maxW="full">
            {diffValues?.length ? (
              <HStack w="full" justifyContent="flex-end" fontSize="0.75rem" py={0.5}>
                <HStack fontSize="0.75rem" fontWeight={600} w="7rem" pr={4} spacing={0} justifyContent="space-between">
                  <Box w="3rem" textAlign="right">
                    Val
                  </Box>
                  <Box w="3rem" color="DMPrimaryColor" textAlign="right">
                    Diff
                  </Box>
                </HStack>
              </HStack>
            ) : null}

            <Box flex="1" overflowY="auto" minHeight="0px" maxW="full" w="full">
              {topSensors ? (
                <Box w="full" pb={3} pt={1} px={4}>
                  {topSensors.map((point: any, index: number) => (
                    <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
                  ))}
                </Box>
              ) : null}

              {middleSensors.length ? (
                <Box w="full" bgColor="rgba(2, 29, 61, 0.03)" py={3} px={4}>
                  {middleSensors.map((point: any, index: number) => (
                    <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
                  ))}
                </Box>
              ) : null}

              {bottomSensors ? (
                <Box w="full" py={3} px={4}>
                  {bottomSensors.map((point: any, index: number) => (
                    <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
                  ))}
                </Box>
              ) : null}
            </Box>
          </VStack>
        </>
      )}

      {!selectedData && (
        <>
          {isPinned ? (
            <SensorFusionCardPlaceholder
              message="Right-click the graph on a date to compare with the selected date"
              icon={DMComputerMouseRightClickIcon}
            />
          ) : (
            <SensorFusionCardPlaceholder
              message="Left-click the graph on the selected date to show values"
              icon={DMComputerMouseLeftClickIcon}
            />
          )}
        </>
      )}
    </VStack>
  );
};
