import React, { forwardRef } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { css } from '@emotion/react';
import { colors } from 'src/theme/foundations/colors';
import { HEADER_HEIGHT_MIN, HEADER_HEIGHT_MAX } from '../SiteTabContent';

interface SitesHeaderProps {
  activeTabIndex?: number;
}

const SitesHeader = forwardRef<HTMLDivElement | null, SitesHeaderProps>(({ activeTabIndex }, ref) => {
  const isAllAssetsTab = activeTabIndex === 0;

  return (
    <HStack
      textTransform="capitalize"
      css={sitesHeaderBaseStyle}
      gap={0}
      height={{ base: `${HEADER_HEIGHT_MAX}px`, 'w-1600': `${HEADER_HEIGHT_MIN}px` }}
    >
      <Box
        fontWeight={500}
        color="#55687D"
        w={{ base: '150px', md: '268px', 'w-1600': '400px' }}
        pl="1rem"
        whiteSpace="nowrap"
        display="flex"
        alignItems="end"
        height="100%"
      >
        Site name
      </Box>

      <HStack
        textAlign="left"
        fontSize="0.875rem"
        flex="1"
        overflowX="scroll"
        gap={0}
        className="scrollable-header"
        alignItems="initial"
        ref={ref}
      >
        <Box
          flex={{ base: 'initial', 'w-1600': '1' }}
          color="#55687D"
          fontWeight={500}
          textAlign="center"
          width={{ base: '90px', 'w-1600': 'initial' }}
          px="0.5rem"
          flexShrink="0"
          lineHeight={{ base: '1.25rem', 'w-1600': 'initial' }}
          whiteSpace={{ base: 'initial', 'w-1600': 'nowrap' }}
          minW={{ base: 'initial', 'w-1600': '120px' }}
        >
          Fixed Assets
        </Box>

        {isAllAssetsTab && (
          <Box
            flex={{ base: 'initial', 'w-1600': '1' }}
            color="#55687D"
            fontWeight={500}
            textAlign="center"
            width={{ base: '90px', 'w-1600': 'initial' }}
            px="0.5rem"
            flexShrink="0"
            lineHeight={{ base: '1.25rem', 'w-1600': 'initial' }}
            whiteSpace={{ base: 'initial', 'w-1600': 'nowrap' }}
            minW={{ base: 'initial', 'w-1600': '120px' }}
          >
            Mobile Assets
          </Box>
        )}

        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          minW="92px"
          px="0.5rem"
          whiteSpace="nowrap"
          alignItems="end"
        >
          <Box
            color="white"
            borderRadius="8px"
            background={colors.health.critical}
            fontSize="12px"
            maxW="200px"
            textAlign="center"
            px="0.5rem"
            height="fit-content"
            whiteSpace="nowrap"
            mb="2px"
          >
            {HEALTH_STATUS.CRITICAL}
          </Box>
        </Box>

        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          minW="86px"
          px="0.5rem"
          whiteSpace="nowrap"
          alignItems="end"
        >
          <Box
            color="black"
            borderRadius="8px"
            background={colors.health.alarm}
            fontSize="12px"
            maxW="200px"
            textAlign="center"
            px="0.5rem"
            whiteSpace="nowrap"
            height="fit-content"
            mb="2px"
          >
            {HEALTH_STATUS.ALARM}
          </Box>
        </Box>

        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          minW="97px"
          px="0.5rem"
          whiteSpace="nowrap"
          alignItems="end"
        >
          <Box
            color="black"
            borderRadius="8px"
            background={colors.health.monitor}
            fontSize="12px"
            maxW="200px"
            textAlign="center"
            px="0.5rem"
            whiteSpace="nowrap"
            height="fit-content"
            mb="2px"
          >
            {HEALTH_STATUS.MONITOR}
          </Box>
        </Box>

        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          minW="97px"
          px="0.5rem"
          whiteSpace="nowrap"
          alignItems="end"
        >
          <Box
            color="white"
            borderRadius="8px"
            background={colors.health.healthy}
            fontSize="12px"
            maxW="200px"
            textAlign="center"
            px="0.5rem"
            whiteSpace="nowrap"
            height="fit-content"
            mb="2px"
          >
            {HEALTH_STATUS.HEALTHY}
          </Box>
        </Box>

        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          minW="138px"
          px="0.5rem"
          whiteSpace="nowrap"
          alignItems="end"
        >
          <Box
            color="white"
            borderRadius="8px"
            background={colors.health.notAvailable}
            fontSize="12px"
            maxW="200px"
            textAlign="center"
            px="0.5rem"
            whiteSpace="nowrap"
            height="fit-content"
            mb="2px"
          >
            Not Monitored
          </Box>
        </Box>
      </HStack>
    </HStack>
  );
});

SitesHeader.displayName = 'SitesHeader';

export default SitesHeader;

const sitesHeaderBaseStyle = css`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #e6e8ec;
  padding-bottom: 0.5rem;

  .scrollable-header::-webkit-scrollbar {
    display: none;
  }

  .scrollable-header {
    -ms-overflow-style: none;
    scrollbar-width: none;
    pointer-events: none;
  }
`;
