import React, { useEffect, useState } from 'react';
import { Center, Table, TableContainer } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import { MaintenanceTableHead } from 'src/features/maintenance-data/maintenance-table/components/MaintenanceTableHead';
import { MaintenanceTableBody } from 'src/features/maintenance-data/maintenance-table/components/MaintenanceTableBody';
import { useLazyGetMaintenanceDataQuery } from 'src/app/api/maintenanceApi';
import { Loading } from 'src/components/Loading';

export const MaintenanceTable = () => {
  const { siteId, machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const { asset_ui_name: assetName } = asset || {};
  const [maintenanceDataPerAsset, setMaintenanceDataPerAsset] = useState<any>([]);
  const [fetchMaintenanceData, { data: maintenanceData, isLoading: isLoadingMaintenanceData }] =
    useLazyGetMaintenanceDataQuery();

  useEffect(() => {
    if (maintenanceData) {
      const { data } = maintenanceData;

      const entries = Object.entries(data).map(([key, value]) => [key, value]);
      const assetEntries = entries.filter((item) => item[0] === assetName);

      if (assetEntries.length) {
        const result = assetEntries[0][1];
        setMaintenanceDataPerAsset(result);
      }
    }
  }, [maintenanceData]);

  useEffect(() => {
    fetchMaintenanceData({ site_id: siteId }, true);
  }, []);

  if (isLoadingMaintenanceData) {
    return <Loading />;
  }

  return maintenanceDataPerAsset.length ? (
    <TableContainer w="100%" maxW="100%" bgColor="#E6E8EC" borderRadius="lg" px={2} pb={2}>
      <Table variant="simple">
        <MaintenanceTableHead />
        <MaintenanceTableBody data={maintenanceDataPerAsset} />
      </Table>
    </TableContainer>
  ) : (
    <Center py={4} w="full" bgColor="#E6E8EC" fontSize="0.875rem" color="#55687D" borderRadius="lg">
      No maintenance data available. Please upload data in order to see it.
    </Center>
  );
};
