import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { format } from 'date-fns';
import { DD_MM_DATE_FORMAT, FUSION_TRENDS_TYPE, HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { healthStatusMap, healthStatusToNumberMap } from '../const';
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
import { colors } from 'src/theme/foundations/colors';
import {
  MAINTENANCE_CHART_CONTEXT,
  MaintenanceChart,
} from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { useSelector } from 'react-redux';
import { maintenanceChartVisible } from 'src/app/store';
import { cloneDeep } from 'lodash';

const healthStatusToShadowColorMap: Record<number, string> = {
  1: '#cc000026',
  2: '#ff891b26',
  3: '#f1bf4226',
  4: '#11975d26',
  5: '#01152b26',
};

const SelectedDateHeader = ({ tooltip }: { tooltip: any }) => {
  return (
    <HStack
      fontSize="12px"
      fontWeight={600}
      spacing={1}
      textTransform="capitalize"
      color="white"
      bg={tooltip && tooltip[1] === 5 ? '#01152B' : tooltip && `health.${healthStatusMap[tooltip[1]]}`}
      borderRadius="full"
      pl={2}
      pr={2}
    >
      <Box>{tooltip && (healthStatusMap[tooltip[1]] === 'na' ? 'Not Monitored' : healthStatusMap[tooltip[1]])}</Box>{' '}
      <Box>{tooltip && format(new Date(tooltip[0]), REGULAR_DATE_FORMAT)}</Box>
    </HStack>
  );
};

export default SelectedDateHeader;

interface ChartProps {
  type: FUSION_TRENDS_TYPE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPointClick: (e: any) => void;
  diagnostics?: any[];
  selectedComponent?: any;
  activeChartPoint?: any;
}

export const Chart = ({ type, onPointClick, diagnostics, selectedComponent, activeChartPoint }: ChartProps) => {
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);
  const diagnosticsChartRef = useRef<ReactECharts>(null);
  const maintenanceChartRef = useRef<ReactECharts>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dataZoom, setDataZoom] = useState<Record<string, number>>({ start: 0, end: 100 });
  const [chartData, setChartData] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [chartDataNAOnly, setChartDataNAOnly] = useState<any>([]);
  const [chartDataRest, setChartDataRest] = useState<any>([]);

  const getChartData = () => {
    const clonedDiagnostics = diagnostics && [...diagnostics];

    const componentDiagnostics =
      clonedDiagnostics &&
      clonedDiagnostics.map((item) => {
        return [
          item[0],
          [
            ...item[1].assets
              .map((asset: any) => {
                return asset.components
                  .filter((component: any) => {
                    if (type === FUSION_TRENDS_TYPE.Component && selectedComponent) {
                      if (component.component_id === selectedComponent.component_id) {
                        return component;
                      }
                    } else {
                      return component;
                    }
                  })
                  .map((component: any) => {
                    return component.diagnostics
                      .map((diagnostic: any) => {
                        return {
                          component_name: component.component_name,
                          component_id: component.component_id,
                          component_health_status: component.health_status,
                          ...diagnostic,
                        };
                      })
                      .flat();
                  })
                  .flat();
              })
              .flat(),
          ],
        ];
      });

    if (type === FUSION_TRENDS_TYPE.Component) {
      return componentDiagnostics
        ? componentDiagnostics.map((item) => {
            return {
              name: format(new Date(item[0]), 'yyyy-MM-dd'),
              value: [item[0], healthStatusToNumberMap[item[1][0]?.component_health_status]],
              clicked: false,
            };
          })
        : [];
    }

    return clonedDiagnostics
      ? clonedDiagnostics.map((item) => {
          return {
            name: format(new Date(item[0]), 'yyyy-MM-dd'),
            value: [item[0], healthStatusToNumberMap[item[1].assets[0].health_status]],
            clicked: false,
          };
        })
      : [];
  };

  const toolbox = {
    left: 'right',
    itemSize: 12,
    top: -7,
    feature: {
      dataZoom: {
        title: {
          zoom: 'Zoom',
          back: '',
        },
        icon: {
          back: 'blank',
        },
        yAxisIndex: 'none',
        emphasis: {
          iconStyle: {
            borderColor: '#7071f3',
          },
        },
        brushStyle: {
          borderWidth: 1,
          borderColor: '#7071f3',
        },
      },
      restore: {
        emphasis: {
          iconStyle: {
            borderColor: '#7071f3',
          },
        },
      },
    },
  };

  const commonOptions = {
    height: '90px',
    grid: { top: 22, right: 16, bottom: 16, left: 24 },
    animation: false,
    dataZoom: [
      {
        type: 'inside',
      },
    ],
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisTick: false,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 10,
        rotate: 45,
        showMaxLabel: true,
        formatter: function (value: string) {
          return format(new Date(value), DD_MM_DATE_FORMAT);
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 6,
      axisLine: true,
      axisLabel: true,
      splitLine: {
        show: false,
      },
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gt: 5,
          lte: 6,
          color: colors.health.notAvailable,
        },
        {
          gt: 4,
          lte: 5,
          color: colors.health.notAvailable,
        },
        {
          gt: 3,
          lte: 4,
          color: colors.health.healthy,
        },
        {
          gt: 2,
          lte: 3,
          color: colors.health.monitor,
        },
        {
          gt: 1,
          lte: 2,
          color: colors.health.alarm,
        },
        {
          gt: 0,
          lte: 1,
          color: colors.health.critical,
        },
      ],
    },
  };

  useEffect(() => {
    const dataNA = chartData.map((item: any) => {
      const status = item.value[1];

      if (status !== healthStatusToNumberMap[HEALTH_STATUS.NOT_AVAILABLE]) {
        return {
          ...item,
          value: [item.value[0], null],
        };
      }

      return item;
    });

    const dataRest = chartData.filter((item: any) => {
      const status = item.value[1];

      if (status !== healthStatusToNumberMap[HEALTH_STATUS.NOT_AVAILABLE]) {
        return item;
      }
    });

    setChartDataNAOnly(dataNA);
    setChartDataRest(dataRest);
  }, [chartData]);

  useEffect(() => {
    const chartDataNAOnlyCopy = cloneDeep(chartDataNAOnly);
    const chartDataRestCopy = cloneDeep(chartDataRest);

    // const filteredChartDataNAOnly = chartDataNAOnlyCopy.filter((item: any) => item.value[1] !== null);
    // const markLinesForNAOnly = filteredChartDataNAOnly.map((item: any) => [
    //   {
    //     coord: [item.value[0], 0],
    //   },
    //   {
    //     coord: [item.value[0], 6],
    //   },
    // ]);
    //
    // const markLinesForRest = chartDataRestCopy.map((item: any) => [
    //   {
    //     coord: [item.value[0], 0],
    //   },
    //   {
    //     coord: [item.value[0], 6],
    //   },
    // ]);

    if (activeChartPoint) {
      if (chartDataNAOnlyCopy) {
        chartDataNAOnlyCopy.forEach((elem: any) => {
          if (elem.value[0] === activeChartPoint[0]) {
            elem.itemStyle = {
              color: 'transparent',
              borderWidth: 0,
            };
          }
        });
      }
      if (chartDataRestCopy) {
        chartDataRestCopy.forEach((elem: any) => {
          if (elem.value[0] === activeChartPoint[0]) {
            elem.itemStyle = {
              color: 'transparent',
              borderWidth: 0,
            };
          }
        });
      }
    }

    setChartOptions({
      ...commonOptions,
      toolbox,
      xAxis: {
        ...commonOptions.xAxis,
        type: isMaintenanceChartVisible.onDiagnostics ? 'time' : 'category',
        axisLabel: {
          ...commonOptions.xAxis.axisLabel,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'none',
          ...dataZoom,
        },
      ],
      series: [
        {
          type: 'scatter',
          smooth: true,
          data: chartDataNAOnlyCopy,
          symbol: 'circle',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          symbolSize: 12,
          zlevel: 2,
          // markLine: {
          //   data: markLinesForNAOnly,
          //   silent: true,
          //   symbolSize: 0,
          //   lineStyle: {
          //     color: '#B1B9C3',
          //   },
          // },
        },
        {
          type: 'line',
          smooth: false,
          data: chartDataRestCopy,
          symbol: 'circle',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          symbolSize: 12,
          lineStyle: {
            color: '#7071f3',
            width: 2,
          },
          areaStyle: {
            color: 'rgba(112, 113, 243, 0.12)',
          },
          zlevel: 2,
          // markLine: {
          //   data: markLinesForRest,
          //   silent: true,
          //   symbolSize: 0,
          //   lineStyle: {
          //     color: '#B1B9C3',
          //   },
          // },
        },
        ...(activeChartPoint && [
          {
            type: 'scatter',
            smooth: true,
            data: [
              {
                name: activeChartPoint[0],
                value: activeChartPoint,
              },
            ],
            symbol: 'circle',
            itemStyle: {
              opacity: 1,
              borderColor: healthStatusToShadowColorMap[activeChartPoint[1]],
              borderWidth: 20,
            },
            symbolSize: 10,
            silent: true,
            zlevel: 3,
            // markLine: {
            //   data: [
            //     [
            //       {
            //         coord: [activeChartPoint[0], 0],
            //       },
            //       {
            //         coord: [activeChartPoint[0], 6],
            //       },
            //     ],
            //   ],
            //   silent: true,
            //   symbolSize: 0,
            //   lineStyle: {
            //     color: '#55687D',
            //   },
            // },
          },
        ]),
      ],
    });
  }, [chartDataNAOnly, chartDataRest, isMaintenanceChartVisible.onDiagnostics, activeChartPoint]);

  useEffect(() => {
    setChartData([...getChartData()]);
  }, [diagnostics, selectedComponent]);

  useEffect(() => {
    if (chartData.length) {
      const latest = chartData[chartData.length - 1].value;
      const isActiveChartPointDateExists = Boolean(
        chartData.filter((item: any) => item.name === format(new Date(activeChartPoint[0]), "yyyy-MM-dd'")).length
      );
      if (activeChartPoint && isActiveChartPointDateExists) {
        onPointClick(activeChartPoint);
      } else {
        onPointClick(latest);
      }
    }
  }, [chartData, selectedComponent]);

  const onEventsHandlers = {
    click: (e: any) => {
      const {
        data: { value },
      } = e;
      onPointClick(value);
    },
    datazoom: (e: any) => {
      if (e.batch?.[0]) {
        const { start, end, startValue, endValue } = e.batch[0];
        const updatedZoom: Record<string, number> = {};

        if (start !== undefined && end !== undefined) {
          updatedZoom.start = start;
          updatedZoom.end = end;
        }

        if (startValue !== undefined && endValue !== undefined) {
          updatedZoom.startValue = startValue;
          updatedZoom.endValue = endValue;
        }

        if (Object.keys(updatedZoom).length) {
          setDataZoom(updatedZoom);
        }
      }
    },
    finished: () => {
      if (fusionTrendChartElement && maintenanceChartRef.current && diagnosticsChartRef.current) {
        const maintenanceChartInstance = maintenanceChartRef.current.getEchartsInstance();
        const diagnosticsChartInstance = diagnosticsChartRef.current.getEchartsInstance();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        echarts.connect([maintenanceChartInstance, diagnosticsChartInstance]);

        diagnosticsChartRef.current.getEchartsInstance().on('restore', (e: any) => {
          diagnosticsChartRef.current?.getEchartsInstance().dispatchAction({
            type: 'dataZoom',
            start: 0,
            end: 100,
          });

          setDataZoom({ start: 0, end: 100 });
        });
      }
    },
    restore: () => setDataZoom({ start: 0, end: 100 }),
  };

  const fusionTrendChartElement = useMemo(
    () => (
      <ReactECharts
        ref={diagnosticsChartRef}
        className="fusion-trend-chart"
        echarts={echarts}
        option={chartOptions}
        onEvents={onEventsHandlers}
      />
    ),
    [selectedComponent, chartOptions]
  );

  return (
    <VStack w="full" ref={wrapperRef} alignItems="stretch" spacing={1}>
      {fusionTrendChartElement}

      {chartData.length > 1 ? (
        <Collapse in={isMaintenanceChartVisible.onDiagnostics} animateOpacity>
          <MaintenanceChart
            selectedComponent={selectedComponent}
            xAxisMin={chartData[0].name}
            xAxisMax={chartData[chartData.length - 1].name}
            context={MAINTENANCE_CHART_CONTEXT.Diagnostics}
            wrapperRef={wrapperRef}
            diagnosticsChartRef={diagnosticsChartRef}
            maintenanceChartRef={maintenanceChartRef}
          />
        </Collapse>
      ) : null}
    </VStack>
  );
};
