import React from 'react';
import { Box, HStack, Tooltip, Text, Image } from '@chakra-ui/react';
import { format } from 'date-fns';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { Asset } from 'src/types/assets';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { AssetFaultType } from 'src/pages/machines/components/AssetFaultType';

type MachineListItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  siteId: string | undefined;
  onMachineItemClick: () => void;
  hasCamera: boolean;
  isNotMonitored: boolean;
};

export const MachineListItem = ({
  item,
  isRequireAttention,
  siteId,
  onMachineItemClick,
  hasCamera,
  isNotMonitored,
}: MachineListItemProps) => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      height="100%"
      px="1rem"
      py="0.75rem"
      spacing={2.5}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={onMachineItemClick}
    >
      <HStack alignItems="center">
        <Box display="flex" flexDirection="column" gap="3px" w="250px">
          <Text fontSize="14px" fontWeight={600} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {item.asset_ui_name}
          </Text>
          <Text fontSize="12px" color="#55687D" fontWeight="500">
            Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gap="5px" ml="20px" w="120px">
          <HealthStatusIndicator type={item.health_status} />
          {item.health_status_changed && (
            <Tooltip label="Health status changed" borderRadius="md">
              <Text fontSize="10px" color="#01152B" fontWeight={600} whiteSpace="nowrap" pt={0.5}>
                Status Change
              </Text>
            </Tooltip>
          )}
        </Box>

        {isRequireAttention &&
          !!item.most_severe_diagnostics[0].fault_type &&
          (item.health_status === HEALTH_STATUS.CRITICAL || item.health_status === HEALTH_STATUS.ALARM) && (
            <Box>
              <AssetFaultType
                faultType={item.most_severe_diagnostics[0].fault_type}
                variant={item.health_status === HEALTH_STATUS.CRITICAL ? 'critical' : 'alarm'}
                styles={{ maxWidth: '265px' }}
              />
            </Box>
          )}
      </HStack>

      <HStack alignItems="center" gap="15px">
        <HStack alignItems="center">
          {hasCamera && (
            <Tooltip label="Camera" borderRadius="md">
              <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
            </Tooltip>
          )}
          {isNotMonitored && (
            <Box
              color="white"
              fontWeight={600}
              borderRadius="8px"
              background="#01152B"
              textAlign="center"
              pl={2}
              pr={2}
              fontSize="10px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              Not Monitored
            </Box>
          )}
        </HStack>
        <MachineSensorsModal
          sensors={item.sensors_health_status}
          title={item.asset_ui_name}
          siteId={siteId!}
          assetId={item.asset_id}
        />
      </HStack>
    </HStack>
  );
};
