import React, { useEffect, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useLazyGetMachineStatusStatisticsQuery } from 'src/app/api/statisticsApi';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { Loading } from 'src/components/Loading';
import { MachineCurrentStatusLegend } from 'src/pages/machines/charts/machine-current-status-chart/MachineCurrentStatusLegend';
import { countMachineStatusData, getOption } from 'src/pages/machines/charts/machine-current-status-chart/utils';
import { MachineCurrentStatusTitle } from 'src/pages/machines/charts/machine-current-status-chart/MachineCurrentStatusTitle';

const TITLE_ROW_HEIGHT = 52;

export const MachineCurrentStatusChart = ({ healthOverviewRowHeight }: any) => {
  const { siteId } = useParams();
  const [fetchMachineStatusStatistics, { data: machineStatusData, isLoading }] = useLazyGetMachineStatusStatisticsQuery(
    {}
  );
  const [data, setData] = useState<any>([]);
  const [status, setStatus] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState<string>();

  useEffect(() => {
    fetchMachineStatusStatistics({ site_id: siteId });
  }, [siteId]);

  useEffect(() => {
    if (machineStatusData?.length) {
      const latest = machineStatusData[machineStatusData.length - 1][1];
      const [data, status] = countMachineStatusData(latest as Array<Record<string, string>>);
      const latestDate = machineStatusData[machineStatusData.length - 1][0] as string;

      setCurrentDate(latestDate);
      setData(data);
      setStatus(status);
    }
  }, [machineStatusData]);

  return (
    <Box w="33%" bg="white" borderRadius="md">
      {isLoading ? (
        <Loading
          w="full"
          style={{
            height: healthOverviewRowHeight || 200,
          }}
        />
      ) : (
        <>
          <MachineCurrentStatusTitle currentDate={currentDate} />
          <HStack w="full" justifyContent="space-between" alignItems="initial">
            <Box w="full">
              <ReactECharts
                style={{
                  height: healthOverviewRowHeight ? healthOverviewRowHeight - TITLE_ROW_HEIGHT : 200,
                }}
                echarts={echarts}
                option={getOption(data, status)}
              />
            </Box>
            <MachineCurrentStatusLegend data={data} />
          </HStack>
        </>
      )}
    </Box>
  );
};
