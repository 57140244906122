import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const maintenanceApi = createApi({
  reducerPath: 'maintenanceApi',
  baseQuery,
  tagTypes: ['MaintenanceData'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getMaintenanceData: builder.query<any, any>({
      query: ({ site_id }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        return { url: `maintenance/per-site?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'MaintenanceData' }],
    }),
  }),
});

export const { useLazyGetMaintenanceDataQuery } = maintenanceApi;
