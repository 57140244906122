import React from 'react';
import {
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  Text,
  Show,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { DMUserGuideIcon } from './Icons';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import pdfFile from 'src/assets/files/UserManualDataMindAIOct2024.pdf';
import { theme } from 'src/theme';

export const UserGuide = (isMobileResolution: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  return (
    <HStack spacing={0}>
      <Tooltip label="User Guide" placement="right" borderRadius="md">
        <IconButton
          aria-label="User Guide"
          size="lg"
          icon={<DMUserGuideIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
          variant="unstyled"
          onClick={onOpen}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', xl: '6xl' }} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalCloseButton top={3} right={1} />
          <ModalBody pb={{ base: 0, xl: 2 }} px={{ base: 0, xl: 2 }} pt={0}>
            <ModalHeader py={7} />
            <iframe
              src={pdfFile}
              style={{
                width: '100%',
                height: isNotMobileResolution ? '80vh' : 'calc(100vh - 3.5rem)',
                borderRadius: isNotMobileResolution ? '0.6rem' : 0,
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Show below="xl">
        <Text onClick={onOpen} cursor="pointer">
          User Guide
        </Text>
      </Show>
    </HStack>
  );
};
