export const maintenanceTableTitleStrings = [
  'Work Order Number',
  'Order Type',
  'Actual Work Description',
  'Equipment Number',
  'Component Name',
  'Maintenance Activity Type',
  'Actual Start Date',
  'Actual End Date',
  'Work Order Status',
  'Labor Hours',
  'Person Responsible',
  'Priority',
  'Plant Number',
];
