import React, { useState, useRef, useEffect } from 'react';
import useSize from '@react-hook/size';
import { Box, HStack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { MachineStatusChart } from 'src/pages/machines/statistics/machine-status-chart/MachineStatusChart';
import { MachineCurrentStatusChart } from 'src/pages/machines/charts/machine-current-status-chart/MachineCurrentStatusChart';
import { CommonFailuresTable } from 'src/pages/machines/statistics/common-failures/CommonFailuresTable';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { MonthlyImpact } from 'src/pages/machines/statistics/operational-gains/MonthlyImpact';
import { MANAGEMENT_ROLE } from 'src/const';
import { useAuth } from 'src/providers/AuthProvider';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';

const OUTER_PADDINGS = '6.5rem';
const INNER_PADDINGS_AND_MARGINS = '3.5rem';
const TABS_HEIGHT = '47px';

const CONTENT_WRAPPER_HEIGHT_CALCULATION = `calc(100vh - ${OUTER_PADDINGS} - ${INNER_PADDINGS_AND_MARGINS} - ${TABS_HEIGHT})`;

const SITE_HEALTH_TITLE_HEIGHT = 48;

interface SiteBusinessInsightsStatisticsProps {
  siteAssetsUINames?: any;
}

export const SiteBusinessInsightsStatistics: React.FC<SiteBusinessInsightsStatisticsProps> = ({
  siteAssetsUINames,
}) => {
  const isBigResolution = useBreakpointValue({
    'w-1600': true,
  });

  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));

  const [isMachinesFailuresTableVisible, setIsMachinesFailuresTableVisible] = useState<boolean>(false);

  const contentWrapperRef = useRef(null);

  const [contentWrapperWidth, contentWrapperHeight] = useSize(contentWrapperRef);

  const [monthlyImpactWrapperHeight, setMonthlyImpactWrapperHeight] = useState<number | null>(null);

  const [healthOverviewWrapperHeight, setHealthOverviewWrapperHeight] = useState<number | null>(null);

  useEffect(() => {
    if (isBigResolution && !isMachinesFailuresTableVisible && contentWrapperHeight) {
      setMonthlyImpactWrapperHeight(contentWrapperHeight * 0.35);
      setHealthOverviewWrapperHeight(contentWrapperHeight * 0.65);
    }
  }, [contentWrapperHeight, isMachinesFailuresTableVisible, isBigResolution]);

  const healthOverviewRowHeight =
    isBigResolution && healthOverviewWrapperHeight
      ? healthOverviewWrapperHeight * 0.5 - SITE_HEALTH_TITLE_HEIGHT / 2
      : null;

  return (
    <Box
      h={{ base: 'initial', 'w-1600': isMachinesFailuresTableVisible ? 'initial' : CONTENT_WRAPPER_HEIGHT_CALCULATION }}
      ref={contentWrapperRef}
    >
      {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
        <>
          {(monthlyImpactWrapperHeight || !isBigResolution) && (
            <Box
              bgColor="#f0f1f2"
              borderRadius="xl"
              h={{
                base: 'initial',
                'w-1600': isMachinesFailuresTableVisible ? 'initial' : monthlyImpactWrapperHeight || 0,
              }}
              display="flex"
              flexDirection="column"
              mb="1rem"
            >
              <HStack justifyContent="space-between" py={3} px={6}>
                <Box fontSize="1rem" fontWeight={600}>
                  Operational Gains Impact Analysis
                </Box>
              </HStack>
              <VStack px={4} spacing={2}>
                <ErrorBoundary FallbackComponent={Fallback}>
                  <MonthlyImpact
                    isMachinesFailuresTableVisible={isMachinesFailuresTableVisible}
                    setIsMachinesFailuresTableVisible={setIsMachinesFailuresTableVisible}
                    isBigResolution={isBigResolution}
                    monthlyImpactWrapperHeight={monthlyImpactWrapperHeight}
                  />
                </ErrorBoundary>
              </VStack>
            </Box>
          )}{' '}
        </>
      ) : null}

      <>
        {(healthOverviewWrapperHeight || !isBigResolution) && (
          <Box
            bgColor="#f0f1f2"
            borderRadius="xl"
            h={{
              base: 'initial',
              'w-1600': isMachinesFailuresTableVisible ? 'initial' : healthOverviewWrapperHeight || 0,
            }}
          >
            <HStack justifyContent="space-between" py={3} px={6}>
              <Box fontSize="1rem" fontWeight={600}>
                Site Health Overview
              </Box>
            </HStack>

            <HStack
              px={4}
              spacing={4}
              h={{
                base: 'initial',
                'w-1600': isMachinesFailuresTableVisible ? 'initial' : healthOverviewRowHeight || 0,
              }}
            >
              <ErrorBoundary FallbackComponent={Fallback}>
                <MachineCurrentStatusChart healthOverviewRowHeight={healthOverviewRowHeight} />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={Fallback}>
                <MachineStatusChart healthOverviewRowHeight={healthOverviewRowHeight} />
              </ErrorBoundary>
            </HStack>
            <Box
              h={{
                base: 'initial',
                'w-1600': isMachinesFailuresTableVisible ? 'initial' : healthOverviewRowHeight || 0,
              }}
              pb={{ base: '4', 'w-1600': isMachinesFailuresTableVisible ? '4' : 'initial' }}
            >
              <ErrorBoundary FallbackComponent={Fallback}>
                <CommonFailuresTable
                  siteAssetsUINames={siteAssetsUINames}
                  healthOverviewRowHeight={healthOverviewRowHeight}
                />
              </ErrorBoundary>
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};
