import { Box, Card, CardBody, Center, HStack, Spinner } from '@chakra-ui/react';
import { Data, PlotRelayoutEvent } from 'plotly.js';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { LEFT_ROLLER_NAMES, RIGHT_ROLLER_NAMES } from 'src/app/responseTransformers';
import {
  BeltMisalignmentDataTransformedEntity,
  BeltMisalignmentDataTransformedResponse,
  BeltMisalignmentLevel,
} from 'src/app/types/sensors';
import { config } from 'src/features/visual-ai/charts/belt-misalignment-plot/config';
import {
  getAnnotations,
  getLeftRollersLayout,
  getRightRollersLayout,
} from 'src/features/visual-ai/charts/belt-misalignment-plot/layout';
import { getRollersMinMaxValues, getStepValues } from 'src/features/visual-ai/charts/belt-misalignment-plot/utils';
import { calculateDateRange, cameraEvidenceShape, findRangeBreaks } from 'src/features/visual-ai/charts/utils';
import { CAMERA_PLOT_BUFFER_VALUE } from 'src/const';
import { HealthStatus } from 'src/types/assets';
import useScreenSize from 'src/hooks/useScreenSize';
import { ChartLegend } from 'src/features/visual-ai/components/ChartLegend';
import { getCameraSource } from 'src/features/visual-ai/utils';
import { BASE64_SVG_PREFIX, iconPlaySelectedSVG, iconPlayUnselectedSVG } from 'src/features/visual-ai/const';

interface MirrorShapedPlotProps {
  title: string;
  noDataDescription: string;
  errorMessage?: string;
  beltMisalignmentData: BeltMisalignmentDataTransformedResponse | undefined;
  isFetchingBeltMisalignment?: boolean;
  isLoadingBeltMisalignment?: boolean;
  isErrorBeltMisalignment?: boolean;
  revision: number;
  lastUpdateComponent?: React.ReactNode;
  latest20Diagnostics?: any;
  latestBeltMisalignmentMeasurements?: any;
  activeEvidenceResourceId?: string;
  updateActiveEvidenceResourceId?: (id: string) => void;
  isLeftRollerPlotVisible: boolean;
  isRightRollerPlotVisible: boolean;
  setIsLeftRollerPlotVisible: any;
  setIsRightRollerPlotVisible: any;
}

export const BeltMisalignmentPlot = ({
  title,
  noDataDescription,
  errorMessage,
  beltMisalignmentData,
  isLoadingBeltMisalignment,
  isFetchingBeltMisalignment,
  isErrorBeltMisalignment,
  revision,
  lastUpdateComponent,
  latest20Diagnostics,
  latestBeltMisalignmentMeasurements,
  activeEvidenceResourceId,
  updateActiveEvidenceResourceId,
  isLeftRollerPlotVisible,
  isRightRollerPlotVisible,
  setIsLeftRollerPlotVisible,
  setIsRightRollerPlotVisible,
}: MirrorShapedPlotProps) => {
  const [dataSource, updateDataSource] = useState<(Data & any) | null>(null);
  const [dateRange, setDateRange] = useState<Array<string>>();
  const [leftTickValues, setLeftTickValues] = useState<Array<number>>([]);
  const [rightTickValues, setRightTickValues] = useState<Array<number>>([]);
  const [tickValuesText, setTickValuesText] = useState<Array<string>>([]);
  const [yAxisRange, setYaxisRange] = useState<Array<number>>([0, 0]);
  const [rangeBreaks, setRangeBreaks] = useState<Array<any>>();
  const [levelValues, setLevelValues] = useState<Record<HealthStatus, BeltMisalignmentLevel>>();
  const screenSize = useScreenSize();
  const [, setUpdate] = useState(0);

  const forceUpdate = () => setUpdate((prevState) => prevState + 1);

  useEffect(() => {
    if (beltMisalignmentData && beltMisalignmentData?.length > 0) {
      const measurements = beltMisalignmentData.map(
        (element: BeltMisalignmentDataTransformedEntity) => element.measurements
      );
      const rangeBreaksResult = findRangeBreaks(beltMisalignmentData[0].measurements.x);
      const [leftMin, leftMax] = getRollersMinMaxValues(LEFT_ROLLER_NAMES, beltMisalignmentData);
      const [rightMin, rightMax] = getRollersMinMaxValues(RIGHT_ROLLER_NAMES, beltMisalignmentData);
      const steps: Array<number> = getStepValues([Math.min(leftMin, rightMin), Math.max(leftMax, rightMax)]);
      const calculatedDateRange = calculateDateRange(
        beltMisalignmentData[0].start_datetime,
        beltMisalignmentData[0].end_datetime
      );

      setRangeBreaks(rangeBreaksResult);
      setYaxisRange([Math.min(leftMin, rightMin), Math.max(leftMax, rightMax)]);

      setRightTickValues(steps);
      setLeftTickValues([...steps.reverse().map((value) => -value)]);
      setTickValuesText([...steps.map((value: number) => String(value))]);

      setDateRange(calculatedDateRange);
      updateDataSource(measurements);
      setLevelValues(beltMisalignmentData[0].levels);
    }
  }, [beltMisalignmentData]);

  const rightY = yAxisRange[1] + 8;
  const leftY = -yAxisRange[1] - 8;
  const arrayOfRightY = Array(20).fill(rightY);
  const arrayOfLeftY = Array(20).fill(leftY);

  const handlePlotClick = (diagnostics: any) => (data: any) => {
    if (data?.points) {
      const point = data.points[0];
      if (point?.data?.marker?.symbol === 'circle') {
        const clickedDate = new Date(point.x);
        const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;

        const matchedEntry = diagnostics.find((entry: any) => {
          const entryDate = new Date(entry.diagnostic_created_at + 'Z');
          const differenceInDays = Math.abs(clickedDate.getTime() - entryDate.getTime()) / twoDaysInMs;

          return differenceInDays <= 1;
        });

        const evidenceResourceId = getCameraSource(matchedEntry.sources_info)?.evidence_resource_id;

        if (matchedEntry && evidenceResourceId) {
          updateActiveEvidenceResourceId && updateActiveEvidenceResourceId(evidenceResourceId);
        }
      }
    }
  };

  const getEvidenceIconSource = (date: any) => {
    const mergedLatestBeltMisalignmentMeasurements = Array.from(
      new Set([...latestBeltMisalignmentMeasurements.left, ...latestBeltMisalignmentMeasurements.right])
    );
    const diagnosticsWithMeasurementsTime = latest20Diagnostics.map((item: any, index: number) => ({
      ...item,
      original_diagnostic_created_at: item.diagnostic_created_at,
      diagnostic_created_at: new Date(mergedLatestBeltMisalignmentMeasurements[index]).toISOString(),
    }));
    const selectedDiagnostic = diagnosticsWithMeasurementsTime.find(
      (entry: any) => getCameraSource(entry.sources_info)?.evidence_resource_id === activeEvidenceResourceId
    );

    const selectedDiagnosticDate = selectedDiagnostic?.diagnostic_created_at.split('T')[0];

    return activeEvidenceResourceId && selectedDiagnosticDate === date?.split('T')[0]
      ? BASE64_SVG_PREFIX + btoa(iconPlaySelectedSVG)
      : BASE64_SVG_PREFIX + btoa(iconPlayUnselectedSVG);
  };

  const getEvidenceIconsSize = () => (yAxisRange[1] < 100 ? 16 : 24);

  return (
    <Box bgColor="#E6E8EC" p={2} pt={0} borderRadius="2xl" w="full">
      <HStack py={4} pb={3} px={4} justifyContent="space-between">
        <Box fontWeight={600}>{title} Trend</Box>
        {lastUpdateComponent}
      </HStack>

      <Card w="full" boxShadow={0}>
        <CardBody p={1}>
          {dataSource && levelValues && dateRange && rangeBreaks ? (
            <Box>
              {isLeftRollerPlotVisible ? (
                <Box className="belt-misalignment-plot">
                  <Plot
                    useResizeHandler
                    data={[
                      ...JSON.parse(
                        JSON.stringify(dataSource.filter((item: any) => LEFT_ROLLER_NAMES.includes(item.name)))
                      ),
                      {
                        ...cameraEvidenceShape,
                        x: latestBeltMisalignmentMeasurements.left,
                        y: arrayOfLeftY,
                      },
                    ]}
                    layout={{
                      ...getLeftRollersLayout(
                        dateRange,
                        rangeBreaks,
                        levelValues,
                        yAxisRange,
                        leftTickValues,
                        tickValuesText,
                        CAMERA_PLOT_BUFFER_VALUE
                      ),
                      height: activeEvidenceResourceId ? (screenSize.height - 397) / 3 : (screenSize.height - 356) / 2,
                      images: latestBeltMisalignmentMeasurements.left.map((date: any, index: number) => ({
                        source: getEvidenceIconSource(date),
                        x: date,
                        y: leftY,
                        xref: 'x',
                        yref: 'y',
                        sizex: 2 * 24 * 60 * 60 * 10000,
                        sizey: getEvidenceIconsSize(),
                        xanchor: 'center',
                        yanchor: 'middle',
                        scale: 1,
                      })),
                      annotations: getAnnotations('Left Roller'),
                    }}
                    config={config}
                    style={{ width: 'auto' }}
                    revision={revision}
                    onClick={(data: any) => {
                      if (data.points[0].data.mode === 'markers+text') {
                        handlePlotClick(latest20Diagnostics)(data);
                        setIsLeftRollerPlotVisible(true);
                        setIsRightRollerPlotVisible(false);
                      }
                    }}
                    onRelayout={(e: Readonly<PlotRelayoutEvent>) => {
                      if (e['xaxis.range']) {
                        forceUpdate();
                      }
                    }}
                  />

                  <ChartLegend measurements={dataSource.filter((item: any) => LEFT_ROLLER_NAMES.includes(item.name))} />
                </Box>
              ) : null}

              {isRightRollerPlotVisible ? (
                <Box className="belt-misalignment-plot">
                  <Plot
                    useResizeHandler
                    data={[
                      ...JSON.parse(
                        JSON.stringify(dataSource.filter((item: any) => RIGHT_ROLLER_NAMES.includes(item.name)))
                      ),
                      {
                        ...cameraEvidenceShape,
                        x: latestBeltMisalignmentMeasurements.right,
                        y: arrayOfRightY,
                      },
                    ]}
                    layout={{
                      ...getRightRollersLayout(
                        dateRange,
                        rangeBreaks,
                        levelValues,
                        yAxisRange,
                        rightTickValues,
                        tickValuesText,
                        CAMERA_PLOT_BUFFER_VALUE
                      ),
                      height: activeEvidenceResourceId ? (screenSize.height - 397) / 3 : (screenSize.height - 356) / 2,
                      images: latestBeltMisalignmentMeasurements.right.map((date: any, index: number) => ({
                        source: getEvidenceIconSource(date),
                        x: date,
                        y: rightY,
                        xref: 'x',
                        yref: 'y',
                        sizex: 2 * 24 * 60 * 60 * 10000,
                        sizey: getEvidenceIconsSize(),
                        xanchor: 'center',
                        yanchor: 'middle',
                        scale: 1,
                      })),
                      annotations: getAnnotations('Right Roller'),
                    }}
                    config={config}
                    style={{ width: 'auto' }}
                    revision={revision}
                    onClick={(data: any) => {
                      if (data.points[0].data.mode === 'markers+text') {
                        handlePlotClick(latest20Diagnostics)(data);
                        setIsLeftRollerPlotVisible(false);
                        setIsRightRollerPlotVisible(true);
                      }
                    }}
                    onRelayout={(e: Readonly<PlotRelayoutEvent>) => {
                      if (e['xaxis.range']) {
                        forceUpdate();
                      }
                    }}
                  />

                  <ChartLegend
                    measurements={dataSource.filter((item: any) => RIGHT_ROLLER_NAMES.includes(item.name))}
                  />
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box h="250px">
              <Center py="100px">
                {isErrorBeltMisalignment ? (
                  errorMessage
                ) : isFetchingBeltMisalignment || isLoadingBeltMisalignment ? (
                  <Spinner size="lg" />
                ) : !beltMisalignmentData || !beltMisalignmentData?.length || beltMisalignmentData[0] === undefined ? (
                  noDataDescription
                ) : (
                  <Spinner size="lg" />
                )}
              </Center>
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
