import React from 'react';
import { DMMachineIcon } from 'src/components/Icons';
import { Box, HStack, SimpleGrid } from '@chakra-ui/react';
import { Asset, AssetsView } from 'src/types/assets';
import { MachineItem } from 'src/pages/machines/components/MachineItem';
import { FormattedText } from 'src/components/FormattedText';

type MachinesGroupProps = {
  title: string;
  assets: Array<Asset>;
  isRequireAttention?: boolean;
  siteLastVerified?: string;
  selectedAssetsView: AssetsView;
};

export const MachinesGroup = ({
  title,
  assets,
  isRequireAttention,
  siteLastVerified,
  selectedAssetsView,
}: MachinesGroupProps) => {
  return (
    <Box w="full" bgColor="#E6E8EC" p={4} borderRadius="lg">
      <HStack color="rgb(100,115,134)" fontSize="0.875rem" fontWeight={600} mb={4}>
        <DMMachineIcon fontSize="1.5rem" />
        <Box>
          <FormattedText label={title} values={{ count: assets.length }} />
        </Box>
      </HStack>

      <SimpleGrid
        columns={selectedAssetsView === 'grid' ? { base: 1, md: 2, lg: 2, xl: 3, '2xl': 4 } : { base: 1 }}
        spacing={2}
      >
        {assets.map((item, index) => (
          <MachineItem
            key={index}
            item={item}
            isRequireAttention={isRequireAttention}
            siteLastVerified={siteLastVerified}
            selectedAssetsView={selectedAssetsView}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
