import { Box, Hide, HStack, Show, VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { css } from '@emotion/react';
import { AppLogo, RazorLabsLogo } from 'src/components/Logo';
import { VideoBackground } from 'src/layouts/components/VideoBackground';

export function AuthLayout() {
  return (
    <HStack css={style} spacing={0}>
      <Box w={{ base: 'full', xl: '50%' }} h="full">
        <Box position="absolute" top={{ base: 0, xl: '60px' }} left={{ base: 0, xl: '70px' }}>
          <Hide below="xl">
            <AppLogo size="184px" />
          </Hide>

          <Show below="xl">
            <VideoBackground />
          </Show>
        </Box>

        <Outlet />
      </Box>

      <Hide below="xl">
        <Box w="50%" h="full">
          <VideoBackground />
          <VStack h="100%" justifyContent="center">
            <RazorLabsLogo />
          </VStack>
        </Box>
      </Hide>
    </HStack>
  );
}

const style = css`
  width: 100%;
  height: 100vh;
`;
