import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormLayout } from 'src/pages/auth/components/FormLayout';
import { ChangePasswordForm } from 'src/pages/auth/components/ChangePasswordForm';
import { analytics } from 'src/analytics';

export const ChangePasswordPage = () => {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    analytics.page({
      name: 'Change password',
      title: 'DataMind Dashboard 2.0 - Change password',
    });
  }, []);

  const userMustChangePassword = localStorage.getItem('userMustChangePassword');

  return (
    <FormLayout
      title="auth.resetPassword.change.heading"
      subtitle="auth.resetPassword.change.description"
      middleRow={userMustChangePassword && !oobCode ? 'auth.resetPassword.change.forceResetNote' : undefined}
    >
      <ChangePasswordForm userMustChangePassword={userMustChangePassword === 'true' || false} oobCode={oobCode} />
    </FormLayout>
  );
};
