import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface PaginationButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const PaginationButton = ({ children, ...rest }: PaginationButtonProps) => {
  return (
    <Button p={0} minWidth={6} h="1.875rem" borderRadius="none" {...rest}>
      {children}
    </Button>
  );
};
