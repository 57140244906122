import React from 'react';
import { PaginationButton } from 'src/features/maintenance-data/maintenance-chart/components/PaginationButton';
import { DMArrowDownIcon, DMArrowUpIcon } from 'src/components/Icons';
import { VStack } from '@chakra-ui/react';
import { MaintenanceTask } from 'src/features/maintenance-data/maintenance-chart/types';

interface PaginationProps {
  onPrevClick: () => void;
  onNextClick: () => void;
  activeTaskPageIndex: number;
  lastTaskPageIndex: number;
  taskPages: Array<Array<MaintenanceTask>>;
}

export const Pagination = ({
  onPrevClick,
  onNextClick,
  taskPages,
  activeTaskPageIndex,
  lastTaskPageIndex,
}: PaginationProps) => {
  return (
    <VStack w={6} h="3.75rem" spacing={0} justifyContent="stretch">
      <PaginationButton
        borderTopRightRadius="lg"
        onClick={onPrevClick}
        isDisabled={activeTaskPageIndex === 0}
        {...paginationButtonProps}
      >
        <DMArrowUpIcon color="#FFFFFF" />
      </PaginationButton>

      <PaginationButton
        borderBottomRightRadius="lg"
        onClick={onNextClick}
        isDisabled={activeTaskPageIndex === lastTaskPageIndex - 1 || !taskPages[activeTaskPageIndex + 1].length}
        {...paginationButtonProps}
      >
        <DMArrowDownIcon color="#FFFFFF" />
      </PaginationButton>
    </VStack>
  );
};

const paginationButtonProps = {
  bgColor: '#6667DD',
  _hover: { bgColor: '#6667DD', opacity: 0.85 },
  _disabled: { bg: '#B1B9C3', cursor: 'not-allowed', _hover: { bg: '#B1B9C3' } },
};
