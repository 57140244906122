import React, { UIEvent, useRef } from 'react';
import { VStack, HStack, Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Site } from 'src/types/sites';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { css } from '@emotion/react';
import { TABLE_ROW_HEIGHT, TABLE_GAP } from '../SiteTabContent';
import { generatePath } from 'react-router-dom';
import { locations } from 'src/app/locations';

type FirstColumnItemProps = {
  item: Site;
  onClick: (siteId: string) => void;
};

const FirstColumnItem = ({ item, onClick }: FirstColumnItemProps) => {
  return (
    <Box
      bgColor="white"
      w="full"
      height={`${TABLE_ROW_HEIGHT}px`}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pl="1rem"
      pr="1rem"
      flexShrink="0"
      cursor="pointer"
      onClick={() => onClick(item.site_id)}
      zIndex={900}
    >
      <Box
        fontWeight={500}
        color="#01152B"
        fontSize="14px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {item.site_name}
      </Box>
      <Box
        fontSize="0.75rem"
        color="#55687D"
        fontWeight={500}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
      </Box>
    </Box>
  );
};

type RowProps = {
  item: Site;
  activeTabIndex?: number;
  onClick: (siteId: string) => void;
};

const Row = ({ item, activeTabIndex, onClick }: RowProps) => {
  const isAllAssetsTab = activeTabIndex === 0;
  const totalAssetsAmount = Object.entries(item.assets_health_state).reduce(
    (total: number, item: Array<any>) => total + item[1],
    0
  );

  return (
    <HStack
      height={`${TABLE_ROW_HEIGHT}px`}
      gap={0}
      w="full"
      flexShrink="0"
      cursor="pointer"
      onClick={() => onClick(item.site_id)}
    >
      <Box
        flex={{ base: 'initial', 'w-1600': '1' }}
        bgColor="white"
        h="full"
        width={{ base: '90px', 'w-1600': 'initial' }}
        px={`${TABLE_GAP}px`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexShrink="0"
        minW={{ base: 'initial', 'w-1600': '120px' }}
      >
        <Text fontSize="14px" fontWeight={500}>
          {totalAssetsAmount}
        </Text>
      </Box>

      {isAllAssetsTab ? (
        <Box
          flex={{ base: 'initial', 'w-1600': '1' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="white"
          width={{ base: '90px', 'w-1600': 'initial' }}
          h="full"
          px={`${TABLE_GAP}px`}
          flexShrink="0"
          minW={{ base: 'initial', 'w-1600': '120px' }}
        >
          <Text fontSize="14px" fontWeight={500}>
            0
          </Text>
        </Box>
      ) : null}

      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="white"
        h="full"
        minW="92px"
        px={`${TABLE_GAP}px`}
      >
        <Text fontSize="14px" fontWeight={500}>
          {item.assets_health_state.critical}
        </Text>
      </Box>

      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="white"
        h="full"
        minW="86px"
        px={`${TABLE_GAP}px`}
      >
        <Text fontSize="14px" fontWeight={500}>
          {item.assets_health_state.alarm}
        </Text>
      </Box>

      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="white"
        h="full"
        minW="97px"
        px={`${TABLE_GAP}px`}
      >
        <Text fontSize="14px" fontWeight={500}>
          {item.assets_health_state.monitor}
        </Text>
      </Box>

      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="white"
        h="full"
        minW="97px"
        px={`${TABLE_GAP}px`}
      >
        <Text fontSize="14px" fontWeight={500}>
          {item.assets_health_state.healthy}
        </Text>
      </Box>

      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="white"
        h="full"
        minW="138px"
        px={`${TABLE_GAP}px`}
      >
        <Text fontSize="14px" fontWeight={500}>
          {item.assets_health_state.na}
        </Text>
      </Box>
    </HStack>
  );
};

type SitesListProps = {
  sites: Site[];
  activeTabIndex?: number;
  handleScroll: (event: UIEvent<HTMLDivElement>) => void;
};

const SitesList = React.forwardRef<HTMLDivElement | null, SitesListProps>(
  ({ sites, activeTabIndex, handleScroll }, ref) => {
    const navigate = useNavigate();

    const mainTableRef = useRef<HTMLDivElement | null>(null);

    const onClick = (siteId: string) => {
      navigate(generatePath(locations.protected.sites.machines, { siteId }));
    };

    const handleTestScroll = (event: UIEvent<HTMLDivElement>) => {
      if (mainTableRef.current) {
        const target = event.target as HTMLDivElement;
        mainTableRef.current.scroll(0, target.scrollTop);
      }
    };
    return (
      <HStack w="full" gap={0.5} alignItems="start" flex="1" overflowY="hidden" css={styles}>
        <VStack
          w={{ base: '150px', md: '268px', 'w-1600': '400px' }}
          gap={2}
          height="full"
          ref={ref}
          overflowY="scroll"
          className="scrollable-column"
          onScroll={handleTestScroll}
        >
          {sites.map((item, index) => (
            <FirstColumnItem key={index} item={item} onClick={onClick} />
          ))}
        </VStack>
        <VStack
          flex="1"
          overflowX="auto"
          overflowY="auto"
          gap={2}
          onScroll={handleScroll}
          h="full"
          ref={mainTableRef}
        >
          {sites.map((item, index) => (
            <Row key={index} item={item} activeTabIndex={activeTabIndex} onClick={onClick} />
          ))}
        </VStack>
      </HStack>
    );
  }
);

SitesList.displayName = 'SitesList';

export default SitesList;

const styles = css`
  .scrollable-column::-webkit-scrollbar {
    display: none;
  }

  .scrollable-column {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
