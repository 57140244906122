export const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '78.75rem',
  '1.5xl': '86rem',
  '2xl': '96em',
  '4xl': '120rem',
  'w-1600': '100rem',
  'w-1440': '90rem',
  'w-1280': '80rem',
  'w-1024': '64rem',
};
