import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

export const Legend = () => {
  return (
    <HStack pt={4} pb={2}>
      <Box w={6} h={2} bgColor="#01152B" borderRadius="full"></Box>
      <Box fontSize="0.75rem" fontWeight={500}>
        Maintenance Task
      </Box>
    </HStack>
  );
};
