import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { DMArrowDownIcon, DMPreferencesIcon } from 'src/components/Icons';
import { dispatch, maintenanceChartVisible } from 'src/app/store';
import { useSelector } from 'react-redux';
import { setIsMaintenanceChartVisible } from 'src/app/slices/applicationSlice';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';

interface PreferencesPopoverProps extends ButtonProps {
  context: MAINTENANCE_CHART_CONTEXT;
}

export const PreferencesPopover = ({ context, ...rest }: PreferencesPopoverProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);

  const onMaintenanceChartSwitchChange = () => {
    const key = context === MAINTENANCE_CHART_CONTEXT.Diagnostics ? 'onDiagnostics' : 'onSensorFusion';
    dispatch(
      setIsMaintenanceChartVisible({
        ...isMaintenanceChartVisible,
        [key]: !isMaintenanceChartVisible[key],
      })
    );
  };

  const getIsCheckedValue = () => {
    return context === MAINTENANCE_CHART_CONTEXT.Diagnostics
      ? isMaintenanceChartVisible.onDiagnostics
      : isMaintenanceChartVisible.onSensorFusion;
  };

  return (
    <Box position="relative">
      <Button
        leftIcon={<DMPreferencesIcon fontSize="1.5rem" />}
        rightIcon={<DMArrowDownIcon />}
        variant="naked"
        fontSize="0.875rem"
        size="sm"
        bgColor={isOpen ? 'rgba(112, 113, 243, 1)' : 'none'}
        color={isOpen ? 'white' : 'rgb(26, 32, 44)'}
        onClick={onToggle}
        mb={1}
        {...rest}
      >
        Preferences
      </Button>

      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverContent border="1px solid rgba(177, 185, 195, 1)" w={56} top={9}>
          <PopoverBody px={4} py={5}>
            <FormControl display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <FormLabel fontSize="0.875rem" mb={0}>
                Maintenance Task
              </FormLabel>
              <Switch
                colorScheme="DMButtonColorScheme"
                isChecked={getIsCheckedValue()}
                onChange={onMaintenanceChartSwitchChange}
              />
            </FormControl>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
