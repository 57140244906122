import React from 'react';
import { IconButton, Input, InputGroup, InputGroupProps, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DMCloseIcon, DMSearchIcon } from 'src/components/Icons';
import { setSearchValue } from 'src/app/slices/applicationSlice';

interface SearchControlProps extends InputGroupProps {
  isTopFixed?: boolean;
  sticky?: boolean;
  bgColor?: string;
  onSearchClose?: () => void;
}

export const SearchControl = ({ isTopFixed, sticky, bgColor, onSearchClose, ...rest }: SearchControlProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChange = (e: any) => {
    const value = e.target.value;
    dispatch(setSearchValue(value));
  };

  const handleSearchClose = () => {
    if (onSearchClose) {
      dispatch(setSearchValue(''));
      onSearchClose();
    }
  };

  return (
    <InputGroup size="sm" variant="data-mind-search" w={{ base: 'full', xl: '20rem' }} mr={4} {...rest}>
      <InputLeftElement
        pointerEvents="none"
        children={<DMSearchIcon fontSize={{ base: '1.5rem', xl: '1rem' }} color={{ base: 'black', xl: 'gray.300' }} />}
      />
      <Input
        type="text"
        onChange={onChange}
        focusBorderColor="#7071f3"
        className="search-input"
        boxShadow={{ base: 0, xl: 'sm' }}
        fontSize={{ base: '1rem', xl: '0.75rem' }}
        placeholder={t('general.searchControl.placeholder').toString()}
        bgColor={bgColor || (!isTopFixed && sticky) ? 'rgba(2, 29, 61, 0.04)' : ''}
      />
      {onSearchClose ? (
        <InputRightElement>
          <IconButton
            variant="naked"
            aria-label="search close"
            icon={<DMCloseIcon fontSize="1rem" />}
            onClick={handleSearchClose}
          />
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
};
