import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import { MaintenanceTaskRaw } from 'src/features/maintenance-data/maintenance-chart/types';

const TaskEntry = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box fontWeight={500}>
      <Box fontSize="0.75rem" color="rgba(85, 104, 125, 1)">
        {title}
      </Box>
      <Box fontSize="0.875rem">{value || '–'}</Box>
    </Box>
  );
};

interface TaskDetailsProps {
  details: MaintenanceTaskRaw;
}

export const TaskDetails = ({ details }: TaskDetailsProps) => {
  return (
    <VStack w="full" alignItems="flex-start" spacing={4}>
      <TaskEntry title="" value={details.actualWorkDescription} />
    </VStack>
  );
};
