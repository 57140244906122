import React, { useRef, UIEvent } from 'react';
import { Fade, TabPanel, Box, useBreakpointValue } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import SitesHeader from 'src/pages/sites/components/SitesHeader';
import SitesList from 'src/pages/sites/components/SitesList';
import Fallback from 'src/components/Fallback';
import { Loading } from 'src/components/Loading';
import useScreenSize from 'src/hooks/useScreenSize';

export const HEADER_HEIGHT_MIN = 29;
export const HEADER_HEIGHT_MAX = 50;
export const TABLE_ROW_HEIGHT = 56;
export const TABLE_GAP = 8;

interface SiteTabContentProps {
  isLoading: boolean;
  isError: boolean;
  searchResults: any;
  activeTabIndex: number;
  tabPanelStyle: any;
}

export const SiteTabContent = ({
  isLoading,
  isError,
  searchResults,
  activeTabIndex,
  tabPanelStyle,
}: SiteTabContentProps) => {
  const scrollableHeaderRef = useRef<HTMLDivElement | null>(null);
  const scrollableBodyRef = useRef<HTMLDivElement | null>(null);

  const isBigScreen = useBreakpointValue({
    'w-1600': true,
  });

  useScreenSize();

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (scrollableHeaderRef.current && scrollableBodyRef.current) {
      const target = event.target as HTMLDivElement;
      scrollableHeaderRef.current.scroll(target.scrollLeft, 0);
      scrollableBodyRef.current.scroll(0, target.scrollTop);
    }
  };

  const calculateTableHeight = () => {
    if (!searchResults) {
      return 0;
    } else {
      const maxTableHeight = screen.height - 11 * 16;
      const contentHeight = searchResults.length * (TABLE_ROW_HEIGHT + TABLE_GAP);
      const diff = maxTableHeight - TABLE_GAP - (isBigScreen ? HEADER_HEIGHT_MIN : HEADER_HEIGHT_MAX) - contentHeight;

      if (diff >= 0) {
        return 'initial';
      } else {
        return 'calc(100vh - 11.5rem)';
      }
    }
  };

  const tableHeight = calculateTableHeight();

  return (
    <TabPanel css={tabPanelStyle}>
      {!isLoading && searchResults && tableHeight ? (
        <Fade in={!isLoading || !activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
          <Box
            p={`${TABLE_GAP}px`}
            bg="#e6e8ec"
            borderRadius="8px"
            h={tableHeight}
            display="flex"
            flexDirection="column"
          >
            <SitesHeader activeTabIndex={activeTabIndex} ref={scrollableHeaderRef} />

            <ErrorBoundary FallbackComponent={Fallback}>
              <SitesList
                sites={searchResults}
                activeTabIndex={activeTabIndex}
                handleScroll={handleScroll}
                ref={scrollableBodyRef}
              />
            </ErrorBoundary>
          </Box>
        </Fade>
      ) : !isError ? (
        <Loading />
      ) : null}
    </TabPanel>
  );
};
