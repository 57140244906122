'use client';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'src/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'src/providers/AuthProvider';
import reportWebVitals from 'src/reportWebVitals';
import { initializeSentry } from 'src/sentry';
import { store } from 'src/app/store';
import { theme } from 'src/theme';
import { toastOptions } from 'src/chakra';
import { App } from 'src/app/App';

if (process.env.NODE_ENV === 'production') {
  initializeSentry();
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ChakraProvider theme={theme} toastOptions={toastOptions}>
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  </ChakraProvider>
);

reportWebVitals();
