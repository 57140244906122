import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

interface MachineCurrentStatusLegendProps {
  data: Array<any>;
}

export const MachineCurrentStatusLegend = ({ data }: MachineCurrentStatusLegendProps) => {
  return (
    <Box w="full" mr={4} display="flex" flexDirection="column" justifyContent="center">
      {data.slice().map((item: any, index: number) => (
        <Box
          key={index}
          flex="1"
          borderBottom={index === data.length - 1 ? '0px' : '1px solid #B1B9C3'}
          display="flex"
          alignItems="center"
          maxH="70px"
        >
          <HStack justifyContent="space-between" w="full" px={2}>
            <Box textTransform="capitalize" fontSize="0.75rem" fontWeight={500} whiteSpace="nowrap">
              {item.name === 'na' ? 'Not Monitored' : item.name}
            </Box>
            <Box fontWeight={600} color={item.itemStyle.color}>
              {item.value}
            </Box>
          </HStack>
        </Box>
      ))}
    </Box>
  );
};
