import React, { Suspense, useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MachineFailureRow } from 'src/pages/machines/components/failure-row/MachineFailureRow';
import { ComponentDiagnostic } from 'src/data/diagnostics';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { DMArrowDownIcon, DMChartIcon, DMSitesIcon } from 'src/components/Icons';
import { FUSION_TRENDS_TYPE, HEALTH_STATUS, REGULAR_DATE_FORMAT, TOAST_STATUS } from 'src/const';
import SelectedDateHeader, { Chart } from 'src/pages/machines/components/Chart';
import { useLazyGetDiagnosticsQuery } from 'src/app/api/diagnosticApi';
import { Asset } from 'src/types/assets';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { format, getMonth, getYear, sub } from 'date-fns';
import { healthStatusToNumberMap } from 'src/pages/machines/const';
import { isDateInRange } from 'src/utils';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from 'src/components/Fallback';
import { Loading } from 'src/components/Loading';

interface FusionTrendsModalProps {
  title: string | undefined;
  type: FUSION_TRENDS_TYPE;
  asset?: Asset;
  componentId?: string;
  componentName?: string;
}

export const MachineFusionTrend = ({ title, type, asset, componentId, componentName }: FusionTrendsModalProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { siteId, machineId } = useParams<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultFromDateValue = sub(new Date(), { months: 6 });
  const defaultToDateValue = new Date();

  const [fromDate, setFromDate] = useState<Date>(defaultFromDateValue);
  const [toDate, setToDate] = useState<Date>(defaultToDateValue);
  const [filteredDiagnostics, setFilteredDiagnostics] = useState<any>([]);
  const [selectedDiagnostics, setSelectedDiagnostics] = useState([]);
  const [fetchDiagnostics, { data: diagnostics, isLoading, isFetching }] = useLazyGetDiagnosticsQuery();
  const [activeChartPoint, setActiveChartPoint] = useState<any>();

  const getDiagnostics = () => {
    if (isOpen && siteId && machineId) {
      fetchDiagnostics(
        {
          site_id: siteId,
          assets_ids: machineId,
          from_date: format(fromDate, "yyyy-MM-dd'T'00:00:00"),
          to_date: format(toDate, "yyyy-MM-dd'T'00:00:00"),
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(machineId)) {
            toast({
              id: machineId,
              status: TOAST_STATUS.Error,
              title: `Failed to load history for asset: ${asset?.asset_ui_name}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  };

  useEffect(() => {
    setFromDate(defaultFromDateValue);
    setToDate(defaultToDateValue);
    getDiagnostics();
  }, [isOpen, siteId, machineId]);

  const onFusionTrendsModalOpen = () => {
    onOpen();
  };

  const selectSpecificDate = (value: string) => {
    const selectedDate = value[0];
    const clonedDiagnostics = [...diagnostics];
    const selectedValue = clonedDiagnostics.filter((item) => item[0] === selectedDate).flat();
    const selectedObject = selectedValue[1];

    const selectedDateDiagnostics = selectedObject.assets
      .map((asset: any) => {
        return asset.components
          .filter((component: any) => {
            if (type === FUSION_TRENDS_TYPE.Component) {
              if (component.component_id === componentId) {
                return component;
              }
            } else {
              return component;
            }
          })
          .map((component: any) => {
            return component.diagnostics
              .map((diagnostic: any) => {
                return {
                  component_name: component.component_name,
                  component_id: component.component_id,
                  ...diagnostic,
                };
              })
              .flat();
          })
          .flat();
      })
      .flat();

    setSelectedDiagnostics(selectedDateDiagnostics);
  };

  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  selectedDiagnostics &&
    selectedDiagnostics.sort((a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status]);

  const onPointClick = (value: any) => {
    setActiveChartPoint(value);
    selectSpecificDate(value);
  };

  useEffect(() => {
    if (diagnostics && !isLoading) {
      const soonerDiagnosticsDate = new Date(diagnostics[0][0]);
      const soonerDiagnosticsDateMonthIndex = getMonth(soonerDiagnosticsDate);
      const fromDateMonthIndex = getMonth(fromDate);
      const soonerDiagnosticsDateYear = getYear(soonerDiagnosticsDate);
      const fromDateYear = getYear(fromDate);

      if (fromDateYear < soonerDiagnosticsDateYear || fromDateMonthIndex < soonerDiagnosticsDateMonthIndex) {
        setFromDate(new Date(soonerDiagnosticsDate));
      }
      // use !isLoading instead of !isFetching to be sure data has been loaded
      const latestDiagnosticDate = diagnostics[diagnostics.length - 1];
      setFilteredDiagnostics(diagnostics);
      setActiveChartPoint([
        latestDiagnosticDate[0],
        healthStatusToNumberMap[latestDiagnosticDate[1].assets[0].health_status],
      ]);
      selectSpecificDate(latestDiagnosticDate);
    }
  }, [diagnostics, isOpen]);

  useEffect(() => {
    if (diagnostics) {
      const fromDateISO = format(fromDate, "yyyy-MM-dd'T'HH:mm:ss");
      const toDateISO = format(toDate, "yyyy-MM-dd'T'HH:mm:ss");

      getDiagnostics();

      const filtered = diagnostics.filter((item: any) =>
        isDateInRange(item[0], new Date(fromDateISO), new Date(toDateISO))
      );

      setFilteredDiagnostics(filtered);
    }
  }, [fromDate, toDate, diagnostics]);

  return (
    <>
      <ButtonNaked leftIcon={<DMChartIcon fontSize="1.5rem" />} label={title} onClick={onFusionTrendsModalOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size="7xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent borderRadius="2xl" mx={12} pb={4} bgColor="white">
          <ModalHeader fontWeight={600} fontSize="1rem">
            {title}
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody position="relative" overflowY="auto" py={0} px={3}>
            {isFetching || isLoading ? (
              <Loading py={24} />
            ) : (
              <Suspense>
                <Box position="sticky" top={0} bgColor="white" mb={4} zIndex={9999}>
                  <Box bgColor="#f0f1f2" borderRadius="2xl" w="full" p={2} pt={1}>
                    <HStack justifyContent="space-between" py={2} pb={3}>
                      <HStack>
                        {type === FUSION_TRENDS_TYPE.Machine ? (
                          <HStack pl={3}>
                            <DMSitesIcon fontSize="1.5rem" />
                            <Box fontSize="1rem" fontWeight={600}>
                              {asset?.asset_ui_name}
                            </Box>
                          </HStack>
                        ) : (
                          <Box fontSize="1rem" pl={3} fontWeight={600}>
                            {componentName}
                          </Box>
                        )}
                        <Box pl={3}>
                          <SelectedDateHeader tooltip={activeChartPoint} />
                        </Box>
                      </HStack>

                      <HStack fontWeight={600} fontSize="0.875rem" pr={3} spacing={5} pt={1}>
                        <HStack spacing={1} mb={1}>
                          <Box>From</Box>
                          <HStack spacing={0}>
                            <DatePicker
                              maxDate={new Date()}
                              selected={fromDate}
                              onChange={(date: Date) => setFromDate(date)}
                              dateFormat={REGULAR_DATE_FORMAT}
                            />
                            <DMArrowDownIcon />
                          </HStack>
                        </HStack>

                        <HStack spacing={1} mb={1}>
                          <Box>To</Box>
                          <HStack spacing={0}>
                            <DatePicker
                              maxDate={new Date()}
                              selected={toDate}
                              onChange={(date: Date) => setToDate(date)}
                              dateFormat={REGULAR_DATE_FORMAT}
                            />
                            <DMArrowDownIcon />
                          </HStack>
                        </HStack>
                      </HStack>
                    </HStack>
                    <Box bgColor="#ffffff" borderRadius="xl">
                      <ErrorBoundary FallbackComponent={Fallback}>
                        <Chart type={type} diagnostics={filteredDiagnostics} onPointClick={onPointClick} />
                      </ErrorBoundary>
                    </Box>
                  </Box>
                </Box>

                <VStack w="full" spacing={2} bgColor="#f0f1f2" p={2} borderRadius="lg">
                  <ErrorBoundary FallbackComponent={Fallback}>
                    {selectedDiagnostics &&
                      selectedDiagnostics.map((item: ComponentDiagnostic, index: number) => (
                        <Box key={index} w="full">
                          <MachineFailureRow item={item} onFusionTrendsModalClose={onClose} isHiddenButtons noShadow />
                        </Box>
                      ))}
                  </ErrorBoundary>
                </VStack>
              </Suspense>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
