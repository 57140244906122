import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  VStack,
  FormHelperText,
} from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';
import { AuthError } from 'firebase/auth';
import { TOAST_STATUS, PASSWORD_PATTERN } from 'src/const';
import { mapAuthCodeToMessage } from 'src/utils';

type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

interface ChangePasswordFormProps {
  userMustChangePassword: boolean;
  oobCode: string | null;
}

export const ChangePasswordForm = ({ userMustChangePassword, oobCode }: ChangePasswordFormProps) => {
  const toast = useToast();
  const { changePassword, user, updatePassword, signOut } = useAuth();
  const navigate = useNavigate();
  const {
    watch,
    register,
    handleSubmit,
    getFieldState,
    getValues,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ChangePasswordFormData>({ mode: 'onChange' });

  const passwordFieldState = getFieldState('password');
  const confirmPasswordFieldState = getFieldState('confirmPassword');

  const passwordFieldValue = getValues('password');
  const confirmPasswordFieldValue = getValues('confirmPassword');

  useEffect(() => {
    trigger('confirmPassword');
  }, [passwordFieldValue]);

  const onSubmit = async (values: FieldValues) => {
    const { password } = values;

    if (isValid) {
      if (userMustChangePassword && user) {
        try {
          await updatePassword(user, password.trim());
          localStorage.removeItem('userMustChangePassword');
          await signOut()
          navigate(locations.public.changed, { replace: true });
        } catch (error: unknown) {
          const authError = error as AuthError;

          toast({
            status: TOAST_STATUS.Error,
            title: mapAuthCodeToMessage(authError.code),
          });
        }
      } else if (oobCode) {
        try {
          await changePassword(oobCode, password.trim());
          navigate(locations.public.changed, { replace: true });
        } catch (error: unknown) {
          const authError = error as AuthError;

          toast({
            status: TOAST_STATUS.Error,
            title: mapAuthCodeToMessage(authError.code),
          });
        }
      }
    }
  };

  const isPasswordFieldInvalid = passwordFieldState.invalid && !!passwordFieldValue;
  const isConfirmPasswordFieldInvalid = confirmPasswordFieldState.invalid && !!confirmPasswordFieldValue;
  const passwordRestrictionsTextColor = isPasswordFieldInvalid ? 'red.500' : passwordFieldValue ? '#58a65c' : 'initial';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={8}>
        <FormControl variant="floating">
          <Input
            w="full"
            type="password"
            isInvalid={isPasswordFieldInvalid}
            variant="data-mind-reset-password"
            placeholder=" "
            {...register('password', {
              required: true,
              pattern: PASSWORD_PATTERN,
            })}
          />
          <FormLabel>
            <FormattedText label="auth.resetPassword.change.form.newPassword" />
          </FormLabel>
          <FormHelperText color={passwordRestrictionsTextColor}>
            <FormattedText label="auth.resetPassword.change.form.passwordRestrictions" />
          </FormHelperText>
        </FormControl>

        <FormControl variant="floating" isInvalid={isConfirmPasswordFieldInvalid}>
          <Input
            type="password"
            variant="data-mind-reset-password"
            isInvalid={isConfirmPasswordFieldInvalid}
            placeholder=" "
            {...register('confirmPassword', {
              required: true,
              validate: (value: string) => {
                if (watch('password') !== value) {
                  return 'Your passwords do not match';
                }
              },
            })}
          />
          <FormLabel>
            <FormattedText label="auth.resetPassword.change.form.confirmPassword" />
          </FormLabel>
          <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mt={8} justifyContent="space-between">
        <Box w="full" textAlign="center">
          <NavLink to={locations.public.login}>
            <Button variant="data-mind-naked-without-uppercase">
              <FormattedText label="auth.resetPassword.change.form.back" />
            </Button>
          </NavLink>
        </Box>

        <Box w="full" textAlign="center" mb={[5, 5, 0]}>
          <Button
            w="full"
            type="submit"
            variant="data-mind-without-uppercase"
            size="lg"
            borderRadius="full"
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            <FormattedText label="auth.resetPassword.change.form.submit" />
          </Button>
        </Box>
      </Flex>
    </form>
  );
};
