import React from 'react';
import {
  Box,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { TaskDetails } from 'src/features/maintenance-data/maintenance-chart/components/TaskDetails';

interface MaintenanceChartPopoverProps {
  isPopoverOpen: boolean;
  onPopoverClose: () => void;
  popoverPosition: Record<string, number>;
  selectedTask?: any;
}

export const MaintenanceChartPopover = ({
  isPopoverOpen,
  onPopoverClose,
  popoverPosition,
  selectedTask,
}: MaintenanceChartPopoverProps) => {
  const workOrderNumber = `Work Order ${selectedTask && selectedTask[5].workOrderNumber}`;

  return (
    <Popover isOpen={isPopoverOpen} onClose={onPopoverClose} placement="top-start">
      <PopoverTrigger>
        <Box position="absolute" visibility="hidden" top={popoverPosition.y} left={popoverPosition.x} zIndex={2} />
      </PopoverTrigger>

      <PopoverContent position="absolute" border="1px solid rgba(177, 185, 195, 1)">
        <PopoverCloseButton right={3} top={3.5} />

        <PopoverHeader fontWeight="600" fontSize="0.875rem" px={4} pt={4} pb={3}>
          <Box>{workOrderNumber}</Box>
        </PopoverHeader>

        <PopoverBody p={4}>{selectedTask ? <TaskDetails details={selectedTask[5]} /> : null}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
