import React, { useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { Asset, AssetsView } from 'src/types/assets';
import { MachinesGroup } from 'src/pages/machines/components/MachinesGroup';

type MachinesListProps = {
  assets?: Array<Asset>;
  site?: any;
  selectedAssetsView: AssetsView;
};

export const MachinesList = ({ assets, site, selectedAssetsView }: MachinesListProps) => {
  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  const siteLastVerified = site?.latest_diagnostic_created_at;

  const assetsUnhealthy = useMemo(
    () =>
      assets
        ? assets
            .filter(
              ({ health_status }) =>
                ![HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR, HEALTH_STATUS.NOT_AVAILABLE].includes(health_status)
            )
            .sort((a: any, b: any) => {
              if (sortByObject[a.health_status] > sortByObject[b.health_status]) return 1;
              if (sortByObject[a.health_status] < sortByObject[b.health_status]) return -1;
              if (a.asset_ui_name > b.asset_ui_name) return 1;
              if (a.asset_ui_name < b.asset_ui_name) return -1;

              return 0;
            })
        : [],
    [assets]
  );

  const assetsHealthy = useMemo(
    () =>
      assets
        ? assets
            .filter(({ health_status }: Asset) =>
              [HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR, HEALTH_STATUS.NOT_AVAILABLE].includes(health_status)
            )
            .sort((a: any, b: any) => {
              if (sortByObject[a.health_status] > sortByObject[b.health_status]) return 1;
              if (sortByObject[a.health_status] < sortByObject[b.health_status]) return -1;
              if (a.asset_ui_name > b.asset_ui_name) return 1;
              if (a.asset_ui_name < b.asset_ui_name) return -1;

              return 0;
            })
        : [],
    [assets]
  );

  return (
    <VStack spacing={4} position="relative" height="calc(100vh - 13rem)" overflowY="auto">
      {assetsUnhealthy.length ? (
        <MachinesGroup
          title="page.machines.groups.unhealthy"
          assets={assetsUnhealthy}
          isRequireAttention
          siteLastVerified={siteLastVerified}
          selectedAssetsView={selectedAssetsView}
        />
      ) : null}

      {assetsHealthy.length ? (
        <MachinesGroup
          title="page.machines.groups.healthy"
          assets={assetsHealthy}
          siteLastVerified={siteLastVerified}
          selectedAssetsView={selectedAssetsView}
        />
      ) : null}
    </VStack>
  );
};
